import { ReactNode, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { AxiosResponse } from 'axios';

import { ProductDetails } from '@Features/seller/products/productsTable/ProductDetails';
import { ProductDto } from '@OpenApi/typescript-axios';
import { productApi } from '@Shared/api/seller/keyValueApi';
import { Drawer } from '@Shared/ui';

type ProductDrawerProps = {
    isDrawerOpen: boolean;
    setDrawerOpen: (arg1: boolean) => void;
    productId: string;
};

export const ProductsDrawer = ({ isDrawerOpen, setDrawerOpen, productId }: ProductDrawerProps) => {
    const dispatch = useDispatch();
    const [drawerExtraContent, setDrawerExtraContent] = useState<ReactNode>(<></>);
    const [drawerTitle, setDrawerTitle] = useState<ReactNode>(<></>);
    const [product, setProduct] = useState<ProductDto>();
    const [isDrawerLoading, setIsDrawerLoading] = useState<boolean>(true);

    useEffect(() => {
        if (productId) {
            setIsDrawerLoading(true);
            productApi
                .getProductDetails(productId)
                .then((res: AxiosResponse<ProductDto, null>) => {
                    setProduct(res.data);
                })
                .finally(() => {
                    setIsDrawerLoading(false);
                });
        }
    }, [dispatch, productId]);

    return (
        <Drawer
            open={isDrawerOpen}
            loading={isDrawerLoading}
            onClose={() => setDrawerOpen(false)}
            title={drawerTitle}
            className="stores-table__drawer"
            extra={drawerExtraContent}
        >
            {!!product && (
                <ProductDetails
                    product={product}
                    setDrawerExtraContent={setDrawerExtraContent}
                    setDrawerTitle={setDrawerTitle}
                />
            )}
        </Drawer>
    );
};
