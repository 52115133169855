import { BrowserCursorClick, FileBlankCopyExchange, NotesPagesNotification } from '@Shared/assets/images/icons';

export const campaignTypes = [
    {
        title: 'Оплата за заказ',
        description: 'Вы платите только за продажи',
        disabled: true,
        icon: <BrowserCursorClick style={{ width: 36, height: 36 }} />,
        navigate: '/product-promotion/campaigns-payment-order',
        hasButton: true,
    },
    {
        title: 'Оплата за размещение',
        description: 'Оплата за публикацию рекламного материала',
        disabled: true,
        icon: <NotesPagesNotification style={{ width: 36, height: 36 }} />,
        hasButton: true,
    },
    {
        title: 'Бартер',
        description: 'Товар продавца обменивается на услуги паблишера',
        disabled: true,
        icon: <FileBlankCopyExchange style={{ width: 36, height: 36 }} />,
        hasButton: true,
    },
];
