import { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { Space } from '@Shared/ui';
import { useGetSellerCommissionsListQuery } from '@Shared/api/rtkQueryApis/sellerCommissions/SellerCommissionsApi';
import {
    FilterEnum,
    FilterObjectType,
    DEFAULT_PAGE_SIZE,
    DEFAULT_PAGE,
} from '@Features/seller/finances/manualVerification/model/types';
import { useUpdateSearchParams } from '@Shared/api/hooks/useUpdateSearchParams';
import { convertToGmtZero } from '@Shared/utils/formatDateTimeUTC';

import ManualVerificationTable from './manualVerificationTable/ManualVerificationTable';
import ManualVerificationFilters from './manualVerificationFilters/ManualVerificationFilters';
import { ApproveCommissionModal, DeclineCommissionModal } from './manualVerificationTable/modals';

const defaultFilterObject: Partial<FilterObjectType> = {
    page: DEFAULT_PAGE,
    limit: DEFAULT_PAGE_SIZE,
};

const initialFilterObject = (searchParams: URLSearchParams): FilterObjectType => ({
    createdAtFrom: searchParams.get(FilterEnum.CREATED_AT_FROM) || '',
    createdAtTo: searchParams.get(FilterEnum.CREATED_AT_TO) || '',
    page: searchParams.get(FilterEnum.PAGE) || defaultFilterObject.page,
    limit: searchParams.get(FilterEnum.LIMIT) || defaultFilterObject.limit,
    sku: searchParams.get(FilterEnum.SKU) || '',
    marketplaceStoreId: searchParams.get(FilterEnum.MARKETPLACE_STORE_ID) || '',
});

const ManualVerificationTableBlock = () => {
    const [searchParams] = useSearchParams();
    const updateSearchParams = useUpdateSearchParams();
    const [filterObject, setFilterObject] = useState<FilterObjectType>(initialFilterObject(searchParams));
    const { data, isFetching } = useGetSellerCommissionsListQuery({
        page: filterObject.page,
        limit: filterObject.limit,
        createdAtFrom: filterObject.createdAtFrom ? convertToGmtZero(filterObject.createdAtFrom) : '',
        createdAtTo: filterObject.createdAtTo ? convertToGmtZero(filterObject.createdAtTo) : '',
        sku: filterObject.sku || '',
        marketplaceStoreId: filterObject.marketplaceStoreId || '',
    });

    useEffect(() => {
        const filteredFilterObject = { ...filterObject };
        Object.keys(filteredFilterObject).forEach((param) => {
            if (filteredFilterObject[param] === defaultFilterObject[param]) {
                delete filteredFilterObject[param];
            }
        });
        updateSearchParams(filteredFilterObject);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterObject]);

    return (
        <>
            <Space
                direction="vertical"
                size={24}
                style={{ width: '100%' }}
            >
                <ManualVerificationFilters
                    filterObject={filterObject}
                    setFilterObject={setFilterObject}
                />
                <ManualVerificationTable
                    data={data}
                    isLoading={isFetching}
                    setFilterObject={setFilterObject}
                />
            </Space>
            <DeclineCommissionModal />
            <ApproveCommissionModal />
        </>
    );
};
export default ManualVerificationTableBlock;
