export enum PaginationSearchOrderEnum {
    LIMIT = 'limit',
    PAGE = 'page',
}

export enum ProductsFilterEnum {
    MARKETPLACES = 'marketplaces[]',
    STORES = 'stores[]',
    CATEGORIES = 'categories[]',
    FAVORITE = 'favorite',
}

export type FilterObjectType = {
    stores: string[];
    marketplaces: string[];
    categories: string[];
    favorite: boolean;
};

export type OptionType = { value: string; label: string };
