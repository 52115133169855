import { SellerPromotionsApi } from './SellerPromotionsApi';

SellerPromotionsApi.enhanceEndpoints({
    addTagTypes: ['PromotionsList', 'Promotion', 'PromotionProductsList'],
    endpoints: {
        getSellerPromotionsList: {
            providesTags: ['PromotionsList'],
        },
        viewThePromotion: {
            providesTags: ['Promotion'],
        },
        patchAppPrivateSellerPromotionSetpromotionvisibility: {
            invalidatesTags: ['PromotionsList', 'Promotion'],
        },
        patchAppPrivateSellerPromotionUpdate: {
            invalidatesTags: ['PromotionsList', 'Promotion'],
        },
        getProductsListForPromotion: {
            providesTags: ['PromotionProductsList'],
        },
        patchAppPrivateSellerPromotionSetpromotionproductvisibility: {
            invalidatesTags: ['PromotionProductsList'],
        },
    },
});

export * from './SellerPromotionsApi';
