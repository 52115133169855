import { PublisherPromotionsApi } from './PublisherPromotionsApi';

PublisherPromotionsApi.enhanceEndpoints({
    addTagTypes: ['PublisherPromotionProductsList'],
    endpoints: {
        getPromotionsProductsListWithLinksBySource: {
            providesTags: ['PublisherPromotionProductsList'],
        },
        addAPromotionProductToFavorites: {
            invalidatesTags: ['PublisherPromotionProductsList'],
        },
        removePromotionProductFromFavorites: {
            invalidatesTags: ['PublisherPromotionProductsList'],
        },
    },
});

export * from './PublisherPromotionsApi';
