import { isFulfilled, isRejectedWithValue, Middleware } from '@reduxjs/toolkit';

import { NotificationType, showNotification } from '@Entities/notifications/model/notificationSlice';

export const errorMiddleware: Middleware =
    ({ dispatch }) =>
    (next) =>
    async (action: unknown) => {
        const notificationOptions =
            action?.meta?.arg?.notificationOptions || action?.meta?.arg?.originalArgs?.notificationOptions;

        if (isRejectedWithValue(action) || action?.error) {
            if (action?.payload?.status === 401) {
                window.location.href = `${process.env.SERVER_URL}/connect/mitgo`;
            }
            if (action?.payload?.status === 422) {
                return next(action);
            }
            dispatch(
                showNotification({
                    componentProps: {
                        type: NotificationType.Error,
                        message:
                            notificationOptions?.errorMessage ||
                            action?.payload?.data?.message ||
                            action?.payload?.message ||
                            action?.error?.message ||
                            'An error occurred!',
                        description: notificationOptions?.errorDescription || null,
                    },
                }),
            );
        } else if (isFulfilled(action) && notificationOptions?.successMessage) {
            dispatch(
                showNotification({
                    componentProps: {
                        type: NotificationType.Success,
                        message: notificationOptions?.successMessage || 'Success!',
                        description: notificationOptions?.successDescription || '',
                    },
                }),
            );
        }

        return next(action);
    };
