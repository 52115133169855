import { Checkbox } from '@Shared/ui';
import { FilterObjectType } from '@Features/publisher/products/model/types';

type ProductsFilterProps = {
    filterObject: FilterObjectType;
    setFilterObject: (arg1: FilterObjectType) => void;
};

const FavoriteCheckbox = ({ filterObject, setFilterObject }: ProductsFilterProps) => {
    return (
        <Checkbox
            checked={filterObject.favorite}
            onChange={(e) => setFilterObject({ ...filterObject, favorite: e.target.checked })}
        >
            Только избранные
        </Checkbox>
    );
};

export default FavoriteCheckbox;
