import { FC } from 'react';
import { RouterProvider } from 'react-router-dom';

import SellerJoyride from './joyride/SellerJoyride';

import { seller_app_router } from '../routes';
import Theme from '../providers/SellerThemeProvider';

const SellerApp: FC = () => {
    return (
        <Theme>
            <SellerJoyride />
            <RouterProvider
                router={seller_app_router}
                fallbackElement={<div>Page not found</div>}
            />
        </Theme>
    );
};

export default SellerApp;
