import { FileOutlined } from '@ant-design/icons';

import { Avatar, Typography } from '@Shared/ui';
import { type ProductDto } from '@Shared/api/rtkQueryApis/sellerPromotions';

const { Text } = Typography;

const ProductNameCell = ({ product }: { product: ProductDto }) => {
    return (
        <>
            <Avatar
                shape="square"
                icon={<FileOutlined />}
                style={{ float: 'left', marginRight: 'var(--ant-margin-sm)' }}
            />

            <Text strong>{product.title}</Text>
        </>
    );
};

export default ProductNameCell;
