import { useState, useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { Layout } from 'antd';

import { PageHeader } from '@Widgets/seller/page-header';
import { PageMenu } from '@Widgets/seller/page-menu';
import { pageMenuItems } from '@Widgets/seller/menuItems';

import './styles.scss';

export default function AppLayout() {
    const [menuCollapsed, setMenuCollapsed] = useState(false);
    const [selectedKey, setSelectedKey] = useState('/');
    const location = useLocation();

    useEffect(() => {
        const href = `/${location?.pathname?.split('/')[1]}`;
        const item = pageMenuItems.find((item) => item.href === href);
        if (item) {
            setSelectedKey(item.key);
        } else {
            setSelectedKey('');
        }
    }, [location?.pathname]);

    return (
        <Layout className="app-layout-container">
            <PageMenu
                menuCollapsed={menuCollapsed}
                selectedKey={selectedKey}
            />
            <Layout>
                <PageHeader
                    menuCollapsed={menuCollapsed}
                    setMenuCollapsed={setMenuCollapsed}
                    selectedKey={selectedKey}
                />
                <Outlet />
            </Layout>
        </Layout>
    );
}
