import { Dispatch, SetStateAction } from 'react';

import { Drawer } from '@Shared/ui';
import { useGetPromotionsProductsDetailsWithLinksBySourceQuery } from '@Shared/api/rtkQueryApis/publisherPromotions';

import { PromotionDetails } from './PromotionDetails';

type ProductDrawerProps = {
    isDrawerOpen: boolean;
    setDrawerOpen: Dispatch<SetStateAction<boolean>>;
    promotionId: string;
    selectedSourceId: string;
};

const PromotionDetailsDrawer = ({ isDrawerOpen, setDrawerOpen, promotionId, selectedSourceId }: ProductDrawerProps) => {
    const { isFetching, data: promotionProduct } = useGetPromotionsProductsDetailsWithLinksBySourceQuery(
        { promotionProduct: promotionId, source: selectedSourceId },
        { skip: !promotionId || !selectedSourceId },
    );

    return (
        <Drawer
            open={isDrawerOpen}
            loading={isFetching}
            onClose={() => setDrawerOpen(false)}
            title="Информация"
        >
            {!!promotionProduct && <PromotionDetails promotionProduct={promotionProduct} />}
        </Drawer>
    );
};

export default PromotionDetailsDrawer;
