import { useEffect, useState } from 'react';
import { Spin, notification, message } from 'antd';
import globalAxios from 'axios';

import { hideNotification, NotificationType } from '@Entities/notifications/model/notificationSlice';
import { MessageType } from '@Entities/messages/model/messagesSlice';
import { selectNotifications, selectMessages } from '@Entities/keyValue/selectors';
import { useAppDispatch, useAppSelector } from '@Shared/api/hooks';
import { OnboardingModal } from '@Features/modals';
import { useGetTheUserInformationQuery } from '@Shared/api/rtkQueryApis/user';

import SellerApp from './SellerApp';
import PublisherApp from './PublisherApp';

globalAxios.defaults.withCredentials = true;

globalAxios.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.response?.status === 401) {
            window.location.href = `${process.env.SERVER_URL}/connect/mitgo`;
        }
        throw error;
    },
);

const TopLevelWrapper = () => {
    const dispatch = useAppDispatch();
    const { data: user, isLoading, isSuccess, error } = useGetTheUserInformationQuery();
    const [messageApi, messageContextHolder] = message.useMessage();
    const [api, contextHolder] = notification.useNotification({
        placement: 'topRight',
        pauseOnHover: true,
    });
    const notifications = useAppSelector(selectNotifications);
    const messages = useAppSelector(selectMessages);
    const [openOnboarding, setOpenOnboarding] = useState<boolean>(false);

    useEffect(() => {
        if (notifications?.componentProps?.message) {
            api[notifications?.componentProps?.type as NotificationType]({
                message: notifications?.componentProps?.message,
                description: notifications?.componentProps?.description,
                btn: notifications?.componentProps?.btn,
                onClose: () => dispatch(hideNotification()),
                duration: notifications?.componentProps?.duration || 5,
            });
        }
    }, [api, dispatch, notifications]);

    useEffect(() => {
        if (messages.componentProps.content) {
            messageApi.open({
                type: messages?.componentProps?.type || MessageType.Info,
                content: messages?.componentProps?.content || '',
            });
        }
    }, [messageApi, dispatch, messages]);

    if (isLoading) {
        return (
            <Spin
                fullscreen
                size="large"
            />
        );
    }

    if (error) {
        return null;
    }

    if (isSuccess) {
        const hasSellerRole = (user?.roles || []).indexOf('ROLE_SELLER') !== -1;
        const hasPublisherRole = (user?.roles || []).indexOf('ROLE_PUBLISHER') !== -1;
        const isRoleSelected = hasSellerRole || hasPublisherRole;

        if (!isRoleSelected && window.location.pathname !== '/welcome') {
            window.location.href = '/welcome';
            return null;
        }

        const showOnboarding = !user?.onboarded && window.location.pathname !== '/welcome' && hasSellerRole;

        const currentApp = hasSellerRole ? <SellerApp /> : <PublisherApp />;

        return (
            <>
                {currentApp}
                {contextHolder}
                {messageContextHolder}
                {showOnboarding && (
                    <OnboardingModal
                        open={openOnboarding}
                        setOpen={setOpenOnboarding}
                    />
                )}
            </>
        );
    }
};

export default TopLevelWrapper;
