import { useEffect, ReactNode } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
    selectState,
    SetupIntegrationFormType,
    submitForm,
    resetForm,
} from '@Entities/forms/seller/stores/model/setupIntegration';
import { fetchData } from '@Entities/keyValue/seller/stores/model/storesSlice';
import { Form, Typography, Input, Button } from '@Shared/ui';
import { FormIntegrationsEnum, KeyValueKeysEnum } from '@Shared/model/seller/types';
import { WildberriesIntegrationDto } from '@OpenApi/typescript-axios';
import { useGetIntegrationDataFromExtension } from '@Shared/api/hooks';

const { Title } = Typography;

type StoreDetailsDrawerProps = {
    setOpen: (arg1: boolean) => void;
    storeId: string;
    setDrawerExtraContent: (arg1: ReactNode) => void;
};

const WildberriesSetupIntegrationForm = ({ setOpen, storeId, setDrawerExtraContent }: StoreDetailsDrawerProps) => {
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    useGetIntegrationDataFromExtension(form);
    const {
        initialData,
        isSubmitted,
        isSubmitting,
        formData,
        errors,
    }: SetupIntegrationFormType<WildberriesIntegrationDto> = useSelector(selectState);

    useEffect(() => {
        setDrawerExtraContent(
            <Button
                type="primary"
                onClick={() => form.submit()}
                loading={isSubmitting}
            >
                Сохранить
            </Button>,
        );
    }, [form, isSubmitting, setDrawerExtraContent, storeId]);

    const handleSubmit = (formValues: typeof formData) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { organization_id, ...restFormData } = formValues;
        dispatch(
            submitForm({
                key: FormIntegrationsEnum.sellerWBUpdateIntegrationForm,
                withNotification: true,
                notificationOptions: {
                    successMessage: 'Интеграция была успешно обновлена',
                    errorMessage: 'Ошибка при попытке обновлении интеграции',
                },
                formData: restFormData,
                params: { storeId },
            }),
        );
    };

    const onFinish = (formData) => {
        handleSubmit(formData);
    };

    const onFinishFailed = (errorInfo) => {
        console.error('Failed:', errorInfo);
    };

    useEffect(() => {
        const isDataEmpty = !Object.keys(initialData).length;
        if (isDataEmpty) {
            form.resetFields();
        } else {
            form.setFieldsValue(initialData);
        }
    }, [form, initialData]);

    useEffect(() => {
        if (isSubmitted) {
            setOpen(false);
            dispatch(resetForm());
            dispatch(fetchData({ key: KeyValueKeysEnum.getStoresList }));
        }
    }, [dispatch, isSubmitted, setOpen]);

    return (
        <Form
            form={form}
            name="integration-wildberries"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            layout="vertical"
            autoComplete="off"
        >
            <Title
                style={{ marginBottom: 8 }}
                level={4}
            >
                Настройка интеграции
            </Title>
            <Form.Item
                hasFeedback
                label="ID организации"
                name="organization_id"
                validateTrigger="onBlur"
                rules={[{ required: true, message: 'Это поле обязательно к заполнению' }]}
                style={{ marginTop: 20 }}
                validateStatus={errors?.organization_id ? 'error' : ''}
                help={errors?.organization_id || null}
            >
                <Input
                    placeholder="Укажите ID организации"
                    disabled
                />
            </Form.Item>
            <Form.Item
                hasFeedback
                label="API authorization token"
                name="api_authorization_token"
                validateTrigger="onBlur"
                rules={[{ required: true, message: 'Это поле обязательно к заполнению' }]}
                style={{ marginTop: 20 }}
                validateStatus={errors?.api_authorization_token ? 'error' : ''}
                help={errors?.api_authorization_token || null}
            >
                <Input placeholder="Укажите API authorization token" />
            </Form.Item>
            <Form.Item
                hasFeedback
                label="WBTokenV3"
                name="wb_token_v3"
                validateTrigger="onBlur"
                rules={[{ required: true, message: 'Это поле обязательно к заполнению' }]}
                style={{ marginTop: 20 }}
                validateStatus={errors?.wb_token_v3 ? 'error' : ''}
                help={errors?.wb_token_v3 || null}
            >
                <Input placeholder="Укажите WB Token V3" />
            </Form.Item>
            <Form.Item
                hasFeedback
                label="wbx-validation-key"
                name="validation_key"
                validateTrigger="onBlur"
                rules={[{ required: true, message: 'Это поле обязательно к заполнению' }]}
                style={{ marginTop: 20 }}
                validateStatus={errors?.validation_key ? 'error' : ''}
                help={errors?.validation_key || null}
            >
                <Input placeholder="Укажите Validation key" />
            </Form.Item>
            <Form.Item
                hasFeedback
                label="x-supplier-id"
                name="seller_device_id"
                validateTrigger="onBlur"
                rules={[{ required: true, message: 'Это поле обязательно к заполнению' }]}
                style={{ marginTop: 20 }}
                validateStatus={errors?.seller_device_id ? 'error' : ''}
                help={errors?.seller_device_id || null}
            >
                <Input placeholder="Укажите x-supplier-id" />
            </Form.Item>
        </Form>
    );
};

export default WildberriesSetupIntegrationForm;
