import { emptySplitApi as api } from '../../full/emptyApi';

const injectedRtkApi = api.injectEndpoints({
    endpoints: (build) => ({
        getSourceContentTypesList: build.query<GetSourceContentTypesListApiResponse, GetSourceContentTypesListApiArg>({
            query: () => ({ url: '/api/publisher/v1/source-content-types/' }),
        }),
        getSourcesList: build.query<GetSourcesListApiResponse, GetSourcesListApiArg>({
            query: () => ({ url: '/api/publisher/v1/source/' }),
        }),
        addASource: build.mutation<AddASourceApiResponse, AddASourceApiArg>({
            query: (queryArg) => ({ url: '/api/publisher/v1/source/', method: 'POST', body: queryArg.sourceDto }),
        }),
        viewASource: build.query<ViewASourceApiResponse, ViewASourceApiArg>({
            query: (queryArg) => ({ url: `/api/publisher/v1/source/${queryArg.source}/` }),
        }),
        putAppPrivatePublisherSourceUpdate: build.mutation<
            PutAppPrivatePublisherSourceUpdateApiResponse,
            PutAppPrivatePublisherSourceUpdateApiArg
        >({
            query: (queryArg) => ({
                url: `/api/publisher/v1/source/${queryArg.source}/`,
                method: 'PUT',
                body: queryArg.sourceDto2,
            }),
        }),
        deleteAppPrivatePublisherSourceDelete: build.mutation<
            DeleteAppPrivatePublisherSourceDeleteApiResponse,
            DeleteAppPrivatePublisherSourceDeleteApiArg
        >({
            query: (queryArg) => ({ url: `/api/publisher/v1/source/${queryArg.source}/`, method: 'DELETE' }),
        }),
        getProductsListWithLinksBySource: build.query<
            GetProductsListWithLinksBySourceApiResponse,
            GetProductsListWithLinksBySourceApiArg
        >({
            query: (queryArg) => ({
                url: `/api/publisher/v1/source/${queryArg.source}/products/`,
                params: {
                    page: queryArg.page,
                    limit: queryArg.limit,
                    stores: queryArg.stores,
                    marketplaces: queryArg.marketplaces,
                    categories: queryArg.categories,
                    bids: queryArg.bids,
                    favorite: queryArg.favorite,
                },
            }),
        }),
        getProductsDetailsWithLinksBySource: build.query<
            GetProductsDetailsWithLinksBySourceApiResponse,
            GetProductsDetailsWithLinksBySourceApiArg
        >({
            query: (queryArg) => ({
                url: `/api/publisher/v1/source/${queryArg.source}/product/${queryArg.productCampaign}/`,
            }),
        }),
        getAppPrivatePublisherSourceProductlistexport: build.query<
            GetAppPrivatePublisherSourceProductlistexportApiResponse,
            GetAppPrivatePublisherSourceProductlistexportApiArg
        >({
            query: (queryArg) => ({ url: `/api/publisher/v1/source/${queryArg.source}/products/export/` }),
        }),
        getSourceTypesList: build.query<GetSourceTypesListApiResponse, GetSourceTypesListApiArg>({
            query: () => ({ url: '/api/publisher/v1/source-types/' }),
        }),
    }),
    overrideExisting: false,
});
export { injectedRtkApi as PublisherSourcesApi };
export type GetSourceContentTypesListApiResponse =
    /** status 200 Get source content types list */ SourceContentTypeList;
export type GetSourceContentTypesListApiArg = void;
export type GetSourcesListApiResponse = /** status 200 Get sources list */ SourceDtoList;
export type GetSourcesListApiArg = void;
export type AddASourceApiResponse = /** status 201 Resource created */ SourceDto3;
export type AddASourceApiArg = {
    sourceDto: SourceDto;
};
export type ViewASourceApiResponse = /** status 200 Resource response */ SourceDto3;
export type ViewASourceApiArg = {
    source: string;
};
export type PutAppPrivatePublisherSourceUpdateApiResponse = unknown;
export type PutAppPrivatePublisherSourceUpdateApiArg = {
    source: string;
    sourceDto2: SourceDto2;
};
export type DeleteAppPrivatePublisherSourceDeleteApiResponse = unknown;
export type DeleteAppPrivatePublisherSourceDeleteApiArg = {
    source: string;
};
export type GetProductsListWithLinksBySourceApiResponse =
    /** status 200 Get product campaign list with links by source */ {
        totalCount?: number;
        page?: number;
        limit?: number;
        items?: ProductCampaignDto[];
    };
export type GetProductsListWithLinksBySourceApiArg = {
    source: string;
    page?: number;
    limit?: number;
    stores?: {
        ''?: string[];
    };
    marketplaces?: {
        ''?: string[];
    };
    categories?: {
        ''?: number[];
    };
    bids?: {
        ''?: string[];
    };
    favorite?: boolean;
};
export type GetProductsDetailsWithLinksBySourceApiResponse =
    /** status 200 Get products details with links by source */ ProductCampaignDto;
export type GetProductsDetailsWithLinksBySourceApiArg = {
    source: string;
    productCampaign: string;
};
export type GetAppPrivatePublisherSourceProductlistexportApiResponse = unknown;
export type GetAppPrivatePublisherSourceProductlistexportApiArg = {
    source: string;
};
export type GetSourceTypesListApiResponse = /** status 200 Get source types list */ SourceTypeList;
export type GetSourceTypesListApiArg = void;
export type SourceContentType = {
    id: number;
    title: string;
    slug: string;
};
export type SourceContentTypeList = {
    items?: SourceContentType[];
};
export type Publisher2 = object;
export type SourceDto3 = {
    id?: string | null;
    publisher?: Publisher2 | null;
    title: string | null;
    status?: SourceStatusEnum | null;
    source_type: number;
    source_content_types?: number[];
    source_url: string | null;
    comment?: string | null;
};
export type SourceDtoList = {
    items?: SourceDto3[];
};
export type SourceDto = {
    title: string | null;
    source_type: number;
    source_content_types?: number[];
    source_url: string | null;
};
export type SourceDto2 = {
    title: string | null;
    source_type: number;
    source_content_types?: number[];
    source_url: string | null;
};
export type Store2 = {
    id: string;
    title: string;
    logo?: string | null;
};
export type Marketplace = {
    id: string;
    title: string;
    slug: MarketPlaceSlugEnum3;
    description?: string | null;
};
export type MarketplaceStore2 = {
    id: string;
    store?: Store2 | null;
    marketplace?: Marketplace | null;
};
export type PaymentType = {
    id: number;
    title: string;
    type: PaymentTypeEnum;
};
export type FeeSetting2 = {
    id: string;
    fee_type?: FeeTypeEnum;
    fee_value?: string;
    system_rate?: string;
    min_total_fee?: string;
};
export type SourceCampaignFee = {
    id: string;
};
export type CampaignDto3 = {
    id?: number | null;
    marketplace_store?: MarketplaceStore2 | null;
    title: string | null;
    description?: string | null;
    logo?: string | null;
    marketplace_url?: string | null;
    payment_type?: PaymentType | null;
    status?: CampaignStatusEnum | null;
    hold_time: number | null;
    fee_setting: FeeSetting2 | null;
    source_campaign_fees?: SourceCampaignFee[];
};
export type Currency = {
    shortCode: string;
    symbol: string;
};
export type ProductCategory = {
    id: number;
    title: string;
};
export type ProductDto = {
    id: string;
    marketplace_store: MarketplaceStore2 | null;
    title: string;
    sku: string;
    description: string;
    price: number | null;
    currency: Currency | null;
    product_category?: ProductCategory | null;
    external_link: string;
    external_id: string;
};
export type FeeSettingDto = {
    fee_type?: FeeTypeEnum | null;
    fee_value?: number | null;
};
export type ProductCampaignDto = {
    id: string;
    campaign?: CampaignDto3 | null;
    product?: ProductDto | null;
    fee_setting?: FeeSettingDto | null;
    visible?: boolean;
    favorite?: boolean;
    tracking_link?: string | null;
    legal_text?: string | null;
    tracking_short_link?: string | null;
};
export type SourceType = {
    id: number;
    type: SourceTypeEnum;
    title: string;
    slug: string;
};
export type SourceTypeList = {
    items?: SourceType[];
};
export enum SourceStatusEnum {
    Verification = 'verification',
    Approved = 'approved',
    Declined = 'declined',
}
export enum MarketPlaceSlugEnum3 {
    Ozon = 'ozon',
    Wildberries = 'wildberries',
}
export enum PaymentTypeEnum {
    Cpa = 'cpa',
    Cpp = 'cpp',
    Barter = 'barter',
}
export enum CampaignStatusEnum {
    Pending = 'pending',
    Active = 'active',
    TopUpBalance = 'top_up_balance',
    AddLegalInfo = 'add_legal_info',
    Verifying = 'verifying',
    Disabled = 'disabled',
}
export enum FeeTypeEnum {
    Fixed = 'fixed',
    Percentage = 'percentage',
}
export enum SourceTypeEnum {
    SocialNetwork = 'social_network',
    Messenger = 'messenger',
    Other = 'other',
}
export const {
    useGetSourceContentTypesListQuery,
    useGetSourcesListQuery,
    useAddASourceMutation,
    useViewASourceQuery,
    usePutAppPrivatePublisherSourceUpdateMutation,
    useDeleteAppPrivatePublisherSourceDeleteMutation,
    useGetProductsListWithLinksBySourceQuery,
    useGetProductsDetailsWithLinksBySourceQuery,
    useGetAppPrivatePublisherSourceProductlistexportQuery,
    useGetSourceTypesListQuery,
} = injectedRtkApi;
