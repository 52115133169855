import { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import { Row, Button } from '@Shared/ui';
import {
    usePatchAppPrivateSellerPromotionUpdateMutation,
    PromotionDto2,
    usePatchAppPrivateSellerPromotionSetpromotionvisibilityMutation,
} from '@Shared/api/rtkQueryApis/sellerPromotions';
import { useAppDispatch } from '@Shared/api/hooks';
import { showMessage, MessageType } from '@Entities/messages/model/messagesSlice';

type PromotionSelectorCardProps = {
    promotion: PromotionDto2 | undefined;
    promotionInfoFormData: Partial<PromotionDto2>;
    promotionId: string;
};

const ActionButtons = ({ promotion, promotionInfoFormData, promotionId }: PromotionSelectorCardProps) => {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useAppDispatch();
    const [editWithApprove] = useState(location?.state?.editWithApprove);
    const [updatePromotionInfo, { isLoading: isUpdating }] = usePatchAppPrivateSellerPromotionUpdateMutation();
    const [setPromotionVisibility, { isLoading: isVisibilityLoading }] =
        usePatchAppPrivateSellerPromotionSetpromotionvisibilityMutation();

    console.log(location.state);

    const handleBack = () => {
        navigate(-1);
    };

    const handlePromotionUpdate = async () => {
        try {
            await updatePromotionInfo({
                id: promotionId,
                promotionDto: { description: promotionInfoFormData?.description },
                notificationOptions: {
                    // eslint-disable-next-line max-len
                    successMessage: `Список товаров для участия в "${promotion?.title}" и ее описание успешно сохранены.`,
                },
            });
            if (editWithApprove) {
                try {
                    await setPromotionVisibility({
                        setPromotionVisibilityDto: { visible: true, promotions: [promotionId] },
                    });
                    dispatch(
                        showMessage({
                            componentProps: {
                                type: MessageType.Success,
                                content: `Вы начали участие в ${promotion?.title}`,
                            },
                        }),
                    );
                } catch (err) {
                    console.error(err);
                }
            }
            handleBack();
        } catch (err) {
            console.error(err);
        }
    };

    return (
        <Row
            justify="space-between"
            style={{ marginTop: 24 }}
        >
            <Button onClick={handleBack}>Отменить</Button>
            <Button
                type="primary"
                onClick={handlePromotionUpdate}
                loading={isUpdating || isVisibilityLoading}
            >
                {editWithApprove ? 'Сохранить и подтвердить участие' : 'Сохранить'}
            </Button>
        </Row>
    );
};

export default ActionButtons;
