import { Switch, Typography, Divider, Space } from '@Shared/ui';

const { Text } = Typography;

const CreateCampaignDraftBlock = ({
    createCampaignDraft,
    setCreateCampaignDraft,
}: {
    createCampaignDraft: boolean;
    setCreateCampaignDraft: (arg1: boolean) => void;
}) => {
    return (
        <>
            <Divider />
            <Space direction="vertical">
                <Space>
                    <Switch
                        value={createCampaignDraft}
                        onChange={(checked) => setCreateCampaignDraft(checked)}
                    />
                    <Text>Создать черновик рекламной кампании</Text>
                </Space>
                <Text type="secondary">
                    Мы создадим рекламную кампанию для текущей интеграции. Вам останется только запустить ее — сделать
                    это можно в разделе «Продвижение товаров».
                </Text>
            </Space>
        </>
    );
};

export default CreateCampaignDraftBlock;
