import { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { Card, Typography, Space } from '@Shared/ui';
// eslint-disable-next-line max-len
import { useGetPublisherTransactionsListQuery } from '@Shared/api/rtkQueryApis/publisherTransactions/PublisherTransactionsApi';
import { FilterEnum, FilterObjectType, DEFAULT_PAGE_SIZE, DEFAULT_PAGE } from '@Features/seller/finances/model/types';
import { useUpdateSearchParams } from '@Shared/api/hooks/useUpdateSearchParams';
import { convertToGmtZero } from '@Shared/utils/formatDateTimeUTC';

import TransactionsTable from './transactionsTable/TransactionsTable';
import TransactionsFilters from './transactionsFilters/TransactionsFilters';

const { Title } = Typography;

const defaultFilterObject: Partial<FilterObjectType> = {
    page: DEFAULT_PAGE,
    limit: DEFAULT_PAGE_SIZE,
};

const initialFilterObject = (searchParams: URLSearchParams): FilterObjectType => ({
    createdAtFrom: searchParams.get(FilterEnum.CREATED_AT_FROM) || '',
    createdAtTo: searchParams.get(FilterEnum.CREATED_AT_TO) || '',
    page: searchParams.get(FilterEnum.PAGE) || defaultFilterObject.page,
    limit: searchParams.get(FilterEnum.LIMIT) || defaultFilterObject.limit,
});

const TransactionsTableCard = () => {
    const [searchParams] = useSearchParams();
    const updateSearchParams = useUpdateSearchParams();
    const [filterObject, setFilterObject] = useState<FilterObjectType>(initialFilterObject(searchParams));
    const { data, isFetching } = useGetPublisherTransactionsListQuery({
        page: filterObject.page,
        limit: filterObject.limit,
        createdAtFrom: filterObject.createdAtFrom ? convertToGmtZero(filterObject.createdAtFrom) : '',
        createdAtTo: filterObject.createdAtTo ? convertToGmtZero(filterObject.createdAtTo) : '',
    });

    useEffect(() => {
        const filteredFilterObject = { ...filterObject };
        Object.keys(filteredFilterObject).forEach((param) => {
            if (filteredFilterObject[param] === defaultFilterObject[param]) {
                delete filteredFilterObject[param];
            }
        });
        updateSearchParams(filteredFilterObject);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterObject]);

    return (
        <Card>
            <Space
                direction="vertical"
                size={24}
                style={{ width: '100%' }}
            >
                <Title
                    level={4}
                    style={{ margin: 0 }}
                >
                    История баланса
                </Title>
                <TransactionsFilters
                    filterObject={filterObject}
                    setFilterObject={setFilterObject}
                />
                <TransactionsTable
                    data={data}
                    isTransactionsLoading={isFetching}
                    setFilterObject={setFilterObject}
                />
            </Space>
        </Card>
    );
};
export default TransactionsTableCard;
