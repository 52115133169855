import { FC, MouseEvent, useEffect, useState } from 'react';
import { useHref, useLinkClickHandler } from 'react-router-dom';
import { FileOutlined } from '@ant-design/icons';

import { Empty, Row, Typography } from '@Shared/ui';
import { StoreDto3 } from '@OpenApi/typescript-axios';

const { Link, Title } = Typography;

type EmptyProductsTableText = 'noStore' | 'noMP';
type EmptyProductsTableViewProps = { stores: StoreDto3[] };

const emptyText: { [key in EmptyProductsTableText]: string } = {
    noStore: 'Добавьте свой магазин и настройте хотя бы одну интеграцию',
    noMP: 'Настройте хотя бы одну интеграцию',
};

export const EmptyProductsTableView: FC<EmptyProductsTableViewProps> = ({ stores }) => {
    const [emptyView, setEmptyView] = useState<EmptyProductsTableText | null>();
    const linkToStoresPage = '/stores';
    const href = useHref(linkToStoresPage);
    const handleClick = useLinkClickHandler(linkToStoresPage);

    useEffect(() => {
        if (!stores?.length) {
            setEmptyView('noStore');
        } else if (!stores.find((item) => !!item.marketplaceStores?.length)) {
            setEmptyView('noMP');
        } else {
            setEmptyView(null);
        }
    }, [stores]);

    return (
        <Empty
            image={
                <FileOutlined
                    height="32"
                    width="32"
                />
            }
            imageStyle={{ height: 32 }}
            description={null}
        >
            <Title level={2}>Здесь пока пусто</Title>
            <Row
                justify="center"
                style={{ marginBottom: 24 }}
            >
                {!!emptyView && emptyText[emptyView]}{' '}
                <Link
                    href={href}
                    onClick={(event) => {
                        if (!event.defaultPrevented) {
                            handleClick(event as MouseEvent<HTMLAnchorElement, MouseEvent>);
                        }
                    }}
                >
                    Перейти в раздел “Магазины”
                </Link>
            </Row>
        </Empty>
    );
};
