import { Layout, theme } from 'antd';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { SourceForm, AddSourceBreadcrumb } from '@Features/publisher/sources';
import { Button, Typography, Space, Card, Row, Col, Grid } from '@Shared/ui';
import { useAddASourceMutation } from '@Shared/api/rtkQueryApis/publisherSources';
import { useGetTheUserInformationQuery } from '@Shared/api/rtkQueryApis/user/UserApi';
import { normalizeErrorsObject } from '@Shared/utils/normalizeErrorsObject';
import { showNotification, NotificationType } from '@Entities/notifications/model/notificationSlice';

import './styles.scss';

const { Content } = Layout;
const { Text, Title, Link } = Typography;
const { useBreakpoint } = Grid;

const AddSourcePage = () => {
    const breakpoints = useBreakpoint();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { data: user } = useGetTheUserInformationQuery();
    const [addSource, { isLoading: isAddSourceLoading, error: addSourceError, isSuccess }] = useAddASourceMutation();

    const {
        token: { colorBgContainer },
    } = theme.useToken();

    const handleSubmit = async (formData) => {
        try {
            await addSource({
                sourceDto: formData,
                notificationOptions: {
                    successMessage: 'Площадка на проверке',
                    successDescription: 'В среднем проверка площадки занимает от 2 до 48 часов',
                },
            });
            if (!user?.publisher?.legalInfo) {
                setTimeout(
                    () =>
                        dispatch(
                            showNotification({
                                componentProps: {
                                    duration: 10,
                                    type: NotificationType.Warning,
                                    message: 'У вас не заполнены юридические данные',
                                    description:
                                        // eslint-disable-next-line max-len
                                        'Чтобы увидеть партнёрские ссылки, заполните юридические данные. Они необходимы для соблюдения закона о маркировке рекламы.',
                                    btn: (
                                        <Button
                                            type="primary"
                                            onClick={() => navigate('/legal-info')}
                                        >
                                            Заполнить
                                        </Button>
                                    ),
                                },
                            }),
                        ),
                    1000,
                );
            }
        } catch (err) {
            console.error(err);
        }
    };

    return (
        <Content
            className="page-content setup-integration-page"
            style={{
                background: colorBgContainer,
                padding: breakpoints.md ? 24 : '24px 0',
            }}
        >
            <AddSourceBreadcrumb />

            <Row
                gutter={[10, 10]}
                style={{
                    rowGap: breakpoints.md ? 10 : 0,
                    flexDirection: breakpoints.lg ? 'row' : 'column',
                    flexWrap: breakpoints.lg ? 'nowrap' : 'wrap',
                }}
            >
                <Col flex="auto">
                    <SourceForm
                        isSubmitting={isAddSourceLoading}
                        isSubmitted={isSuccess}
                        errors={normalizeErrorsObject(addSourceError?.data?.errors)}
                        initialData={{}}
                        handleSubmit={handleSubmit}
                        isAddSourceForm
                    />
                </Col>
                <Col style={{ flex: breakpoints.lg ? '320px 0 0' : 'auto' }}>
                    <Card style={{ width: '100%', background: '#fff' }}>
                        <Space
                            direction="vertical"
                            size="middle"
                        >
                            <Title
                                type="secondary"
                                level={3}
                            >
                                FAQ
                            </Title>
                            <Text type="secondary">Подробнее о площадках здесь</Text>
                            <Link
                                href="https://support.mitgo.com/ru/knowledge-base/article/%D0%BA%D0%B0%D0%BA-%D0%BD%D0%B0%D1%87%D0%B0%D1%82%D1%8C-%D1%80%D0%B0%D0%B1%D0%BE%D1%82%D1%83-%D1%81-%D1%82%D0%B0%D0%BA%D0%BF%D1%80%D0%BE%D0%B4%D0%B0%D0%BC_1"
                                target="_blank"
                            >
                                Как начать работу с Такпродам?
                            </Link>
                            <Link
                                onClick={() => {
                                    window.location.href = `mailto:${process.env.SUPPORT_EMAIL}`;
                                }}
                            >
                                Нужна помощь?
                            </Link>
                        </Space>
                    </Card>
                </Col>
            </Row>
        </Content>
    );
};
export default AddSourcePage;
