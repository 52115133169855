import { useState, useEffect } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { Alert } from 'antd';
import { InfoCircleFilled } from '@ant-design/icons';

import { useAppSelector, useAppDispatch } from '@Shared/api/hooks';
import { setRun, goToStep } from '@Entities/joyride/model/joyrideSlice';
import { PublisherOnboardingStatuses } from '@Shared/model/joyrideTypes';
import {
    useGetProductsListWithLinksBySourceQuery,
    useGetSourcesListQuery,
} from '@Shared/api/rtkQueryApis/publisherSources';
import { useGetTheUserInformationQuery } from '@Shared/api/rtkQueryApis/user/UserApi';
import { Row, Typography, Space, Button } from '@Shared/ui';
import { PAGE_SIZE } from '@Features/seller/CampaignTypeSelection';
import {
    SourceSelectBlock,
    ProductCatalogTable,
    ProductCatalogDrawer,
    ProductCatalogFilters,
} from '@Features/publisher/products';
import { ProductsFilterEnum, FilterObjectType } from '@Features/publisher/products/model/types';
import { isElementInViewport } from '@Shared/utils/isElementInViewPort';

const { Text } = Typography;

const defaultFilterObject = (searchParams: URLSearchParams): FilterObjectType => ({
    stores: searchParams.getAll(ProductsFilterEnum.STORES) || [],
    marketplaces: searchParams.getAll(ProductsFilterEnum.MARKETPLACES),
    categories: searchParams.getAll(ProductsFilterEnum.CATEGORIES),
    favorite: Boolean(searchParams.get(ProductsFilterEnum.FAVORITE)),
});

const ProductsTab = () => {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { status: onboardingStepStatus, stepIndexOrder } = useAppSelector((state) => state.joyride);
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedSourceId, setSourceId] = useState('');
    const [isDrawerOpen, setDrawerOpen] = useState(false);
    const [currentProductId, setCurrentProductId] = useState('');
    const [filterObject, setFilterObject] = useState<FilterObjectType>(defaultFilterObject(searchParams));
    const { data: user } = useGetTheUserInformationQuery();
    const { data: sourcesData, isLoading: isSourcesLoading } = useGetSourcesListQuery();
    const { data: productsData, isFetching: isProductsLoading } = useGetProductsListWithLinksBySourceQuery(
        {
            source: selectedSourceId,
            page: currentPage,
            limit: PAGE_SIZE,
            favorite: filterObject?.favorite || false,
            stores: filterObject?.stores?.length ? filterObject.stores : undefined,
            marketplaces: filterObject?.marketplaces?.length ? filterObject.marketplaces : undefined,
            categories: filterObject?.categories?.length ? filterObject.categories : undefined,
        },
        { skip: !selectedSourceId },
    );

    // joyride onboarding step
    useEffect(() => {
        if (onboardingStepStatus === PublisherOnboardingStatuses.GETTING_STARTED) {
            if (stepIndexOrder === 1) {
                dispatch(setRun(true));
                dispatch(goToStep({ status: PublisherOnboardingStatuses.GETTING_STARTED, order: 2 }));
            } else if (stepIndexOrder === 4) {
                const stepTarget = document.querySelector('#joyride-products-external-link');
                if (stepTarget && !isElementInViewport(stepTarget)) {
                    dispatch(setRun(false));
                    stepTarget.scrollIntoView({ behavior: 'smooth', block: 'end' });
                    setTimeout(() => {
                        dispatch(setRun(true));
                        dispatch(goToStep({ status: PublisherOnboardingStatuses.GETTING_STARTED, order: 4 }));
                    }, 500);
                } else {
                    dispatch(setRun(true));
                }
            }
        }
    }, [dispatch, onboardingStepStatus, stepIndexOrder]);

    useEffect(() => {
        if (sourcesData?.items?.length) {
            setSourceId(sourcesData?.items[0]?.id || '');
        }
    }, [sourcesData, setSourceId]);

    return (
        <Space
            direction="vertical"
            size="large"
            style={{ width: '100%' }}
        >
            {!user?.publisher?.legalInfo && (
                <Row style={{ marginBottom: 24 }}>
                    <Alert
                        className="campaigns-tab__legal-info-alert"
                        // eslint-disable-next-line max-len
                        description="Чтобы увидеть партнёрские ссылки, заполните юридические данные. Они необходимы для соблюдения закона о маркировке рекламы."
                        type="info"
                        showIcon
                        icon={<InfoCircleFilled style={{ color: 'black', opacity: '0.45' }} />}
                        banner
                        action={
                            <Button
                                type="primary"
                                onClick={() => navigate('/legal-info')}
                            >
                                Заполнить
                            </Button>
                        }
                    />
                </Row>
            )}
            <Row align="top">
                <Text>
                    В этом подразделе представлен список доступных для продвижения продуктов, работающих по модели
                    оплаты “Оплата за заказ” (CPA).
                </Text>
            </Row>
            <SourceSelectBlock
                setSourceId={setSourceId}
                selectedSourceId={selectedSourceId}
                isSourcesLoading={isSourcesLoading}
                sources={sourcesData}
            />
            <ProductCatalogFilters
                filterObject={filterObject}
                setFilterObject={setFilterObject}
                sourceId={selectedSourceId}
            />
            <ProductCatalogTable
                data={productsData}
                isProductsLoading={isProductsLoading || isSourcesLoading}
                setCurrentPage={setCurrentPage}
                setCurrentProductId={setCurrentProductId}
                setDrawerOpen={setDrawerOpen}
            />
            <ProductCatalogDrawer
                isDrawerOpen={isDrawerOpen}
                setDrawerOpen={setDrawerOpen}
                productId={currentProductId}
                sourceId={selectedSourceId}
            />
        </Space>
    );
};
export default ProductsTab;
