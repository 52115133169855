import { emptySplitApi as api } from '../../full/emptyApi';

const injectedRtkApi = api.injectEndpoints({
    endpoints: (build) => ({
        getPromotionsProductsListWithLinksBySource: build.query<
            GetPromotionsProductsListWithLinksBySourceApiResponse,
            GetPromotionsProductsListWithLinksBySourceApiArg
        >({
            query: (queryArg) => ({
                url: `/api/publisher/v1/source/${queryArg.source}/promotion/product/`,
                params: {
                    page: queryArg.page,
                    limit: queryArg.limit,
                    marketplaces: queryArg.marketplaces,
                    categories: queryArg.categories,
                    favorite: queryArg.favorite,
                },
            }),
        }),
        getPromotionsProductsDetailsWithLinksBySource: build.query<
            GetPromotionsProductsDetailsWithLinksBySourceApiResponse,
            GetPromotionsProductsDetailsWithLinksBySourceApiArg
        >({
            query: (queryArg) => ({
                url: `/api/publisher/v1/source/${queryArg.source}/promotion/product/${queryArg.promotionProduct}/`,
            }),
        }),
        addAPromotionProductToFavorites: build.mutation<
            AddAPromotionProductToFavoritesApiResponse,
            AddAPromotionProductToFavoritesApiArg
        >({
            query: (queryArg) => ({
                url: `/api/publisher/v1/promotion/product/${queryArg.promotionProduct}/favorites/`,
                method: 'POST',
            }),
        }),
        removePromotionProductFromFavorites: build.mutation<
            RemovePromotionProductFromFavoritesApiResponse,
            RemovePromotionProductFromFavoritesApiArg
        >({
            query: (queryArg) => ({
                url: `/api/publisher/v1/promotion/product/${queryArg.promotionProduct}/favorites/`,
                method: 'DELETE',
            }),
        }),
    }),
    overrideExisting: false,
});
export { injectedRtkApi as PublisherPromotionsApi };
export type GetPromotionsProductsListWithLinksBySourceApiResponse =
    /** status 200 Get promotions products list with links by source */ {
        totalCount?: number;
        page?: number;
        limit?: number;
        items?: PromotionProductDto[];
    };
export type GetPromotionsProductsListWithLinksBySourceApiArg = {
    source: string;
    page?: number;
    limit?: number;
    marketplaces?: {
        ''?: string[];
    };
    categories?: {
        ''?: number[];
    };
    favorite?: boolean;
};
export type GetPromotionsProductsDetailsWithLinksBySourceApiResponse =
    /** status 200 Get promotions products details with links by source */ PromotionProductDto;
export type GetPromotionsProductsDetailsWithLinksBySourceApiArg = {
    source: string;
    promotionProduct: string;
};
export type AddAPromotionProductToFavoritesApiResponse = /** status 204 Empty response */ void;
export type AddAPromotionProductToFavoritesApiArg = {
    promotionProduct: string;
};
export type RemovePromotionProductFromFavoritesApiResponse = /** status 204 Resource removed */ void;
export type RemovePromotionProductFromFavoritesApiArg = {
    promotionProduct: string;
};
export type MarketplaceDto2 = {
    id: string;
    title: string;
    slug: MarketPlaceSlugEnum3;
    description?: string | null;
};
export type StoreDto3 = {
    id: string;
    title: string;
    comment?: string | null;
    logo?: string | null;
    marketplaceStores?: MarketplaceStoreDto2[];
    active: boolean;
};
export type MarketplaceStoreDto2 = {
    id: string;
    marketplace: MarketplaceDto2;
    store: StoreDto3;
};
export type PromotionDto2 = {
    id: string;
    title: string;
    description: string | null;
    marketplace_store: MarketplaceStoreDto2;
    start_date: string;
    end_date: string;
    active?: boolean;
    visible?: boolean;
    orderAmount?: number | null;
    discountValue?: string | null;
};
export type Store2 = {
    id: string;
    title: string;
    logo?: string | null;
};
export type Marketplace = {
    id: string;
    title: string;
    slug: MarketPlaceSlugEnum3;
    description?: string | null;
};
export type MarketplaceStore2 = {
    id: string;
    store?: Store2 | null;
    marketplace?: Marketplace | null;
};
export type Currency = {
    shortCode: string;
    symbol: string;
};
export type ProductCategory = {
    id: number;
    title: string;
};
export type ProductDto = {
    id: string;
    marketplace_store: MarketplaceStore2 | null;
    title: string;
    sku: string;
    description: string;
    price: number | null;
    currency: Currency | null;
    product_category?: ProductCategory | null;
    external_link: string;
    external_id: string;
};
export type FeeSettingDto = {
    fee_type?: FeeTypeEnum | null;
    fee_value?: number | null;
};
export type PromotionProductDto = {
    id: string;
    price?: number | null;
    price_discount?: number | null;
    price_percent?: number | null;
    promotion?: PromotionDto2 | null;
    product?: ProductDto | null;
    legal_text?: string | null;
    tracking_link?: string | null;
    fee_setting?: FeeSettingDto | null;
    favorite?: boolean;
    tracking_short_link?: string | null;
};
export enum MarketPlaceSlugEnum3 {
    Ozon = 'ozon',
    Wildberries = 'wildberries',
}
export enum FeeTypeEnum {
    Fixed = 'fixed',
    Percentage = 'percentage',
}
export const {
    useGetPromotionsProductsListWithLinksBySourceQuery,
    useGetPromotionsProductsDetailsWithLinksBySourceQuery,
    useAddAPromotionProductToFavoritesMutation,
    useRemovePromotionProductFromFavoritesMutation,
} = injectedRtkApi;
