import { closeModal } from '@Entities/modals/model/modalsSlice';
import { selectModals } from '@Entities/keyValue/selectors';
import { useAppDispatch, useAppSelector } from '@Shared/api/hooks';
import { Modal, Typography, Row } from '@Shared/ui';
import { ModalKeysType } from '@Shared/model/modalTypes';

import ApproveCommissionForm from './ApproveCommissionForm';

const { Title, Text } = Typography;

const ApproveCommissionModal = () => {
    const dispatch = useAppDispatch();
    const modalState = useAppSelector(selectModals);
    const isOpen = modalState.modalKey === ModalKeysType.approveCommission && modalState.open;

    const handleCancel = () => {
        dispatch(closeModal());
    };

    return (
        <Modal
            title={
                <Title
                    level={4}
                    style={{ marginTop: 0 }}
                >
                    Изменить сумму заказа?
                </Title>
            }
            open={isOpen}
            onCancel={handleCancel}
            footer={null}
        >
            <>
                <Row>
                    <Text type="secondary">
                        Вы уверены, что хотите изменить сумму заказа? Сумма может быть изменена только из-за частичного
                        выкупа.
                    </Text>
                </Row>
                <ApproveCommissionForm handleModalClose={handleCancel} />
            </>
        </Modal>
    );
};
export default ApproveCommissionModal;
