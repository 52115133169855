import { createElement } from 'react';
import Icon, { MessageOutlined } from '@ant-design/icons';

import {
    BookLegalLow,
    SingleUser,
    ExitLogOut,
    ShoppingBag,
    ItemsStarsSquare,
    CreditCardUp,
    LinkSearchLoap,
    StatisticsAnalytics,
    // SaleDiscountPromotion,
} from '@Shared/assets/images/icons';

export const pageMenuItems = [
    // {
    //     key: 'dashboard',
    //     href: '/',
    //     icon: createElement(DashboardOutlined),
    //     label: 'Дашбоард',
    // },
    {
        key: 'stores',
        href: '/stores',
        label: 'Магазины',
        icon: <Icon component={() => <ShoppingBag />} />,
    },
    {
        key: 'products',
        href: '/product-promotion',
        label: 'Продвижение товаров',
        className: 'joyride-product-promotion-menu-item',
        icon: <Icon component={() => <ItemsStarsSquare />} />,
    },
    // {
    //     key: 'promotions',
    //     href: '/promotions',
    //     label: 'Акции и промокоды',
    //     icon: <Icon component={() => <SaleDiscountPromotion />} />,
    // },
    {
        key: 'finances',
        href: '/finances',
        label: 'Финансы',
        className: 'joyride-finances-menu-item',
        icon: <Icon component={() => <CreditCardUp />} />,
    },
    {
        key: 'statistics',
        href: '/statistics',
        label: 'Статистика',
        icon: <Icon component={() => <StatisticsAnalytics />} />,
    },
    {
        key: 'messenger',
        href: '/messenger',
        icon: createElement(MessageOutlined),
        label: 'Мессенджер',
        disabled: true,
    },
    // {
    //     key: 'settings',
    //     href: '/settings',
    //     icon: createElement(SettingOutlined),
    //     label: 'Настройки',
    // },
];

export const userMenuItems = [
    {
        key: 'profile',
        label: 'Профиль',
        disabled: true,
        icon: <Icon component={() => <SingleUser />} />,
    },
    {
        key: 'legal-info',
        href: '/legal-info',
        label: 'Юридические данные',
        icon: <Icon component={() => <BookLegalLow style={{ opacity: '0.45' }} />} />,
    },
    {
        key: 'ref-link',
        modal: 'ref-link',
        label: 'Ссылка-приглашение',
        icon: <Icon component={() => <LinkSearchLoap style={{ opacity: '0.45' }} />} />,
    },
    {
        type: 'divider',
    },
    {
        key: 'logout',
        label: 'Выйти',
        onClick: () => (location.href = `${process.env.SERVER_URL}/logout-init`),
        icon: <Icon component={() => <ExitLogOut />} />,
    },
];
