import { Key, useCallback, useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { QuestionCircleOutlined, EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import { Button, Tooltip, type TableProps } from 'antd';

import { renderEmpty } from '@Features/shared';
import { Flex, InputNumber, Select, Space, Table, Typography, Grid, Row } from '@Shared/ui';
import { type ProductCampaign } from '@Shared/api/rtkQueryApis/sellerCampaigns';
import { formatPrice } from '@Shared/utils/formatPrice';

import { ComissionCell } from './ComissionCell';
import { ProductNameCell } from './ProductNameCell';
import { CampaignContext } from './campaignContext';
import { ActionType, actionOptions, PAGE_SIZE } from './types';

const { Text } = Typography;
const { useBreakpoint } = Grid;

type TableRowSelection<T extends object = object> = TableProps<T>['rowSelection'];

type CampaingProductsTableProps = {
    items: readonly ProductCampaign[];
    isLoading?: boolean;
};

const columns = [
    {
        title: 'Название товара',
        dataIndex: 'title',
        key: 'title',
        ellipsis: true,
        width: 300,
        render: (text: unknown, { product }: ProductCampaign) => <ProductNameCell product={product} />,
    },
    {
        title: 'Стоимость',
        dataIndex: 'price',
        key: 'price',
        width: 150,
        render: (text: unknown, { product }: ProductCampaign) =>
            formatPrice({ price: product.price, currency: product.currency || {} }),
    },
    {
        title: 'Ссылка на МП',
        dataIndex: 'external_link',
        key: 'external_link',
        ellipsis: true,
        width: 'max(30%, 240px)',
        render: (text: unknown, { product }: ProductCampaign) => (
            <a
                target="_blank"
                rel="noopener noreferrer"
                href={product.external_link}
            >
                {product.external_link}
            </a>
        ),
    },
];

const CampaingProductsTable = ({ items = [], isLoading }: CampaingProductsTableProps) => {
    const breakpoints = useBreakpoint();
    const { fee_setting, applyAction, totalItems, currentPage = 1, setCurrentPage } = useContext(CampaignContext);
    const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([]);
    const [selectedAction, setAction] = useState<string>(ActionType.changeCommission);
    const [selectedCommission, setCommission] = useState<number | null>(Math.floor(fee_setting?.fee_value * 100) || 10);

    const onSelectChange = (newSelectedRowKeys: Key[]) => {
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection: TableRowSelection<ProductCampaign> = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const handleApplyAction = useCallback(() => {
        applyAction({
            producstsIds: selectedRowKeys as string[],
            action: selectedAction as ActionType,
            commission: selectedCommission / 100,
        });
        setSelectedRowKeys([]);
    }, [applyAction, selectedRowKeys, selectedAction, selectedCommission]);

    const showColumn = {
        title: (
            <>
                Видимость &nbsp;
                <Tooltip title="Вы можете остановить продвижение товара, скрыв его из каталога товаров">
                    <QuestionCircleOutlined
                        width={16}
                        height={16}
                    />
                </Tooltip>
            </>
        ),
        dataIndex: 'showCell',
        key: 'showCell',
        width: 140,
        render: (text: unknown, { id, visible }: ProductCampaign) => (
            <Button
                type="link"
                style={{ color: visible ? 'var(--ant-color-link)' : 'var(--ant-color-text-description)' }}
                icon={visible ? <EyeOutlined /> : <EyeInvisibleOutlined />}
                onClick={() => {
                    applyAction({
                        producstsIds: [id],
                        action: visible ? ActionType.showStop : ActionType.showStart,
                    });
                }}
            />
        ),
    };

    const commissionColumn = {
        title: 'Коммисия',
        dataIndex: 'commissionCell',
        key: 'commissionCell',
        width: 240,
        render: (text: unknown, product: ProductCampaign) => (
            <ComissionCell
                product={product}
                campaign_fee_setting={fee_setting}
                applyAction={applyAction}
            />
        ),
    };

    const tableFooter = useCallback(() => {
        return (
            <Flex
                style={{ flexDirection: breakpoints.lg ? 'row' : 'column' }}
                justify="space-between"
                // style={{ marginTop: 'var(--ant-margin-sm)' }}
            >
                <Space>
                    {selectedRowKeys.length > 0 ? (
                        <>
                            <Text type="secondary">Выбрано {selectedRowKeys.length}</Text>
                            <Button
                                type="link"
                                onClick={() => setSelectedRowKeys([])}
                            >
                                Отменить выбор
                            </Button>
                        </>
                    ) : (
                        <>&nbsp;</>
                    )}
                </Space>

                <Row
                    style={{ gap: 12, width: breakpoints.lg ? 'auto' : '100%' }}
                    wrap={breakpoints.md ? false : true}
                >
                    <Select
                        options={actionOptions}
                        onChange={setAction}
                        value={selectedAction}
                        style={{ flex: breakpoints.lg ? 'unset' : 'auto', width: breakpoints.md ? '225px' : '100%' }}
                    />
                    <InputNumber
                        disabled={false}
                        value={selectedCommission}
                        min={2}
                        max={100}
                        onChange={setCommission}
                        addonAfter="%"
                        step="1"
                        style={{ flex: breakpoints.lg ? 'unset' : 'auto', width: breakpoints.md ? '100px' : '100%' }}
                    />
                    <Button
                        type="primary"
                        ghost
                        onClick={handleApplyAction}
                        style={{ width: breakpoints.md ? 'auto' : '100%' }}
                    >
                        Подтвердить
                    </Button>
                </Row>
            </Flex>
        );
    }, [breakpoints, handleApplyAction, selectedAction, selectedCommission, selectedRowKeys.length]);

    return (
        <Table
            footer={tableFooter}
            scroll={{ x: '1000px' }}
            dataSource={items.map((item) => ({ ...item, key: item.id }))}
            columns={[...columns, showColumn, commissionColumn]}
            rowSelection={rowSelection}
            loading={isLoading}
            pagination={{
                showSizeChanger: false,
                hideOnSinglePage: true,
                pageSize: PAGE_SIZE,
                position: ['bottomLeft'],
                current: currentPage,
                total: totalItems,
                onChange: setCurrentPage,
            }}
            locale={{
                emptyText: renderEmpty({
                    content: (
                        <>
                            Добавьте свой магазин и настройте хотя бы одну интеграцию
                            <br />
                            <Link to="/stores">Перейти в раздел “Магазины”</Link>
                        </>
                    ),
                }),
            }}
        />
    );
};

export default CampaingProductsTable;
