import { MoreOutlined, EditOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { Dropdown } from 'antd';
import { useNavigate } from 'react-router-dom';

import { Button } from '@Shared/ui';
import { CampaignStatusEnum } from '@OpenApi/typescript-axios';

import type { MenuProps } from 'antd';

type ActionsMenuProps = {
    setCancelCampaignModalOpen: (arg1: boolean) => void;
    campaignStatus?: CampaignStatusEnum | null;
    slug?: string | null;
};

const ActionsMenu = ({ setCancelCampaignModalOpen, campaignStatus, slug }: ActionsMenuProps) => {
    const navigate = useNavigate();

    const items: MenuProps['items'] = [
        {
            key: 'Изменить',
            label: 'Изменить',
            icon: <EditOutlined />,
            onClick: ({ domEvent }) => {
                domEvent.stopPropagation();
                navigate(`/product-promotion/campaigns-payment-order/${slug}`);
            },
        },
        {
            type: 'divider',
        },
        ...(campaignStatus === CampaignStatusEnum.Disabled
            ? []
            : [
                  {
                      key: 'Отключить',
                      label: 'Отключить',
                      icon: <CloseCircleOutlined />,
                      danger: true,
                      onClick: ({ domEvent }) => {
                          domEvent.stopPropagation();
                          setCancelCampaignModalOpen(true);
                      },
                  },
              ]),
    ];

    return (
        <Dropdown
            menu={{ items }}
            placement="bottomRight"
        >
            <Button
                type="text"
                onClick={(e) => e.stopPropagation()}
            >
                <MoreOutlined />
            </Button>
        </Dropdown>
    );
};

export default ActionsMenu;
