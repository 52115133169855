import { Dispatch, SetStateAction } from 'react';

import { renderEmpty } from '@Features/shared';
import { Table, Typography } from '@Shared/ui';
import {
    TransactionDto,
    CommissionDto,
    TransactionTypeEnum,
    GetSellerTransactionsListApiResponse,
} from '@Shared/api/rtkQueryApis/sellerTransactions/SellerTransactionsApi';
import { formatDateUTC } from '@Shared/utils/formatDateTimeUTC';
import { FilterObjectType } from '@Features/seller/finances/model/types';

import TransactionAmountCell from './TransactionAmountCell';
import CommissionIdCell from './CommissionIdCell';

const { Text } = Typography;

type ProductCatalogTableProps = {
    data: GetSellerTransactionsListApiResponse | undefined;
    isTransactionsLoading: boolean;
    setFilterObject: Dispatch<SetStateAction<FilterObjectType>>;
};

const transactionsTypeMap = {
    [TransactionTypeEnum.Commission]: 'Комиссия за продвижение',
    [TransactionTypeEnum.Manual]: 'Ручная',
    [TransactionTypeEnum.Replenishment]: 'Пополнение баланса',
    [TransactionTypeEnum.Payout]: 'Вывод денег',
};

const TransactionsTable = ({ data, isTransactionsLoading, setFilterObject }: ProductCatalogTableProps) => {
    const columns = [
        {
            title: 'Дата',
            dataIndex: 'created_at',
            key: 'created_at',
            ellipsis: true,
            render: (date: string) => <>{formatDateUTC(date)}</>,
        },
        {
            title: 'Тип транзакции',
            dataIndex: 'transaction_type',
            key: 'transaction_type',
            render: (transaction_type: TransactionTypeEnum) => <>{transactionsTypeMap[transaction_type]}</>,
        },
        {
            title: 'ID комиссии',
            dataIndex: 'commission',
            key: 'commission',
            render: (commission: CommissionDto) =>
                commission?.id ? <CommissionIdCell commissionId={commission.id} /> : null,
        },
        {
            title: 'Сумма',
            dataIndex: 'transaction_amount',
            key: 'transaction_amount',
            render: (transaction_amount: number, record: TransactionDto) => (
                <TransactionAmountCell
                    transaction_amount={transaction_amount || ''}
                    currency={record.currency}
                />
            ),
        },
    ];

    return (
        <Table
            dataSource={data?.items?.map((item) => ({ ...item, key: item.id }))}
            loading={isTransactionsLoading}
            columns={columns}
            scroll={{ x: '1000px' }}
            pagination={{
                showSizeChanger: true,
                hideOnSinglePage: true,
                pageSizeOptions: ['10', '25', '50'],
                pageSize: data?.limit,
                position: ['bottomLeft'],
                current: data?.page,
                total: data?.totalCount,
                onChange: (page, pageSize) =>
                    setFilterObject((prevFilterObject) => {
                        const hasPageSizeChanged = pageSize !== +prevFilterObject.limit;
                        return {
                            ...prevFilterObject,
                            page: hasPageSizeChanged ? 1 : page,
                            limit: pageSize,
                        };
                    }),
            }}
            locale={{
                emptyText: renderEmpty({
                    content: <Text>По выбранным параметрам не найдено ни одной транзакции</Text>,
                }),
            }}
        />
    );
};

export default TransactionsTable;
