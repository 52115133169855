import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { theme } from 'antd';

import {
    selectState,
    SetupIntegrationFormType,
    submitForm,
} from '@Entities/forms/seller/stores/model/setupIntegration';
import { FormIntegrationsEnum } from '@Shared/model/seller/types';
import { Button, Form, Input, Grid, Popover, Typography } from '@Shared/ui';
import { WildberriesIntegrationDto } from '@OpenApi/typescript-axios';
import { ConfirmLeavePrompt } from '@Features/shared';
import { showNotification, NotificationType } from '@Entities/notifications/model/notificationSlice';
import { useGetIntegrationDataFromExtension } from '@Shared/api/hooks';

import CreateCampaignDraftBlock from '../CreateCampaignDraftBlock';

import './styles.scss';

const { useBreakpoint } = Grid;
const { Link } = Typography;

const fieldsHelperText = {
    api_authorization_token: (
        <>
            {'Создайте новый токен в разделе: '}
            <Link
                href="https://seller.wildberries.ru/supplier-settings/access-to-api"
                target="_blank"
            >
                Профиль --&gt; Настройки --&gt; Доступ к API
            </Link>
            {' с доступом к разделам '}
            <strong>Цены и Контент </strong>
            {'и скопируйте в это поле полученный код'}
        </>
    ),
    organization_id: (
        <>
            {'В разделе '}
            <strong>Внешний трафик </strong>
            {'('}
            <Link
                href="https://cmp.wildberries.ru/external-traffic"
                target="_blank"
            >
                https://cmp.wildberries.ru/external-traffic
            </Link>
            {') '}
            {'найдите поле '}
            <strong>ID поставщика </strong>
            {'и скопируйте его значение в это поле'}
        </>
    ),
    wb_token_v3: (
        <>
            {'Скопируйте в это поле '}
            <strong>Cookie Value </strong>
            {'параметра '}
            <strong>WBTokenV3 </strong>
            {'из файлов Cookie. Подробнее '}
            <Link
                href="https://docs.google.com/document/d/1Fd14LnRYRoCNI7qp9BF1JqYJW0X0lCnK-Xleb7PnWTA/edit"
                target="_blank"
            >
                здесь
            </Link>
        </>
    ),
    validation_key: (
        <>
            {'Скопируйте в это поле '}
            <strong>Cookie Value </strong>
            {'параметра '}
            <strong>wbx-validation-key </strong>
            {'из файлов Cookie. Подробнее '}
            <Link
                href="https://docs.google.com/document/d/1Fd14LnRYRoCNI7qp9BF1JqYJW0X0lCnK-Xleb7PnWTA/edit"
                target="_blank"
            >
                здесь
            </Link>
        </>
    ),
    seller_device_id: (
        <>
            {'Скопируйте в это поле '}
            <strong>Cookie Value </strong>
            {'параметра '}
            <strong>x-supplier-id </strong>
            {'из файлов Cookie. Подробнее '}
            <Link
                href="https://docs.google.com/document/d/1Fd14LnRYRoCNI7qp9BF1JqYJW0X0lCnK-Xleb7PnWTA/edit"
                target="_blank"
            >
                здесь
            </Link>
        </>
    ),
};

type SetupIntegrationFormProps = {
    storeId: string;
};

const WildberriesIntegrationForm = ({ storeId }: SetupIntegrationFormProps) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const breakpoints = useBreakpoint();
    const [form] = Form.useForm();
    useGetIntegrationDataFromExtension(form);
    const [hasUnsavedChanges, setUnsavedChanges] = useState(false);
    const [createCampaignDraft, setCreateCampaignDraft] = useState(true);
    const { isSubmitted, isSubmitting, formData, errors }: SetupIntegrationFormType<WildberriesIntegrationDto> =
        useSelector(selectState);

    const {
        token: { colorTextDescription, colorPrimary },
    } = theme.useToken();

    const handleSubmit = (formValues: typeof formData) => {
        dispatch(
            submitForm({
                key: FormIntegrationsEnum.sellerWBSetupIntegrationForm,
                withNotification: true,
                notificationOptions: {
                    successMessage: 'Интеграция была успешно создана',
                    errorMessage: 'Ошибка при попытке создания интеграции',
                },
                formData: formValues,
                params: { storeId, createCampaignDraft },
            }),
        );
    };

    const onFinish = (formData) => {
        handleSubmit(formData);
    };

    const onFinishFailed = (errorInfo) => {
        console.error('Failed:', errorInfo);
    };

    const handleBack = useCallback(() => {
        navigate(-1);
    }, [navigate]);

    useEffect(() => {
        if (isSubmitting) {
            setUnsavedChanges(false);
        }
    }, [isSubmitting]);

    useEffect(() => {
        if (isSubmitted) {
            if (createCampaignDraft) {
                setTimeout(() =>
                    dispatch(
                        showNotification({
                            componentProps: {
                                type: NotificationType.Info,
                                message: 'У вас появилась новая рекламная кампания',
                                description:
                                    // eslint-disable-next-line max-len
                                    'Проверьте и отредактируйте информацию о ней. Запустите кампанию, чтобы начать быстрое продвижение товаров.',
                                btn: (
                                    <Button
                                        type="link"
                                        onClick={() => navigate('/product-promotion/campaigns')}
                                    >
                                        Рекламные кампании
                                    </Button>
                                ),
                            },
                        }),
                        1000,
                    ),
                );
            }
        }
    }, [colorPrimary, createCampaignDraft, dispatch, isSubmitted, navigate]);

    const renderTooltip = (field) => (
        <Popover
            content={<div style={{ maxWidth: 500 }}>{fieldsHelperText[field]}</div>}
            placement={breakpoints.md ? 'right' : 'top'}
        >
            <QuestionCircleOutlined style={{ marginLeft: 5, color: colorTextDescription }} />
        </Popover>
    );

    return (
        <Form
            form={form}
            name="integration-wildberries"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            onFieldsChange={() => setUnsavedChanges(true)}
            autoComplete="off"
            className="setup-integration-page__form"
            layout={breakpoints.lg ? 'horizontal' : 'vertical'}
        >
            <ConfirmLeavePrompt
                hasUnsavedChanges={hasUnsavedChanges}
                allowsPaths={['/stores/setup-integration']}
            />
            <Form.Item
                hasFeedback
                name="api_authorization_token"
                label={<>API authorization token {renderTooltip('api_authorization_token')}</>}
                validateTrigger="onBlur"
                rules={[{ required: true, message: 'Это поле обязательно к заполнению' }]}
                style={{ marginTop: 20 }}
                validateStatus={errors?.api_authorization_token ? 'error' : ''}
                help={errors?.api_authorization_token || null}
            >
                <Input placeholder="Укажите API authorization token" />
            </Form.Item>
            <Form.Item
                hasFeedback
                name="organization_id"
                label={<>ID поставщика {renderTooltip('organization_id')}</>}
                validateTrigger="onBlur"
                rules={[{ required: true, message: 'Это поле обязательно к заполнению' }]}
                style={{ marginTop: 20 }}
                validateStatus={errors?.organization_id ? 'error' : ''}
                help={errors?.organization_id || null}
            >
                <Input placeholder="Укажите ID поставщика" />
            </Form.Item>
            <Form.Item
                hasFeedback
                name="wb_token_v3"
                label={<>WBTokenV3 {renderTooltip('wb_token_v3')}</>}
                validateTrigger="onBlur"
                rules={[{ required: true, message: 'Это поле обязательно к заполнению' }]}
                style={{ marginTop: 20 }}
                validateStatus={errors?.wb_token_v3 ? 'error' : ''}
                help={errors?.wb_token_v3 || null}
            >
                <Input placeholder="Укажите WBTokenV3" />
            </Form.Item>
            <Form.Item
                hasFeedback
                name="validation_key"
                label={<>wbx-validation-key {renderTooltip('validation_key')}</>}
                validateTrigger="onBlur"
                rules={[{ required: true, message: 'Это поле обязательно к заполнению' }]}
                style={{ marginTop: 20 }}
                validateStatus={errors?.validation_key ? 'error' : ''}
                help={errors?.validation_key || null}
            >
                <Input placeholder="Укажите wbx-validation-key" />
            </Form.Item>

            <Form.Item
                hasFeedback
                name="seller_device_id"
                label={<>x-supplier-id {renderTooltip('seller_device_id')}</>}
                validateTrigger="onBlur"
                rules={[{ required: true, message: 'Это поле обязательно к заполнению' }]}
                style={{ marginTop: 20 }}
                validateStatus={errors?.seller_device_id ? 'error' : ''}
                help={errors?.seller_device_id || null}
            >
                <Input placeholder="Укажите x-supplier-id" />
            </Form.Item>
            <CreateCampaignDraftBlock
                createCampaignDraft={createCampaignDraft}
                setCreateCampaignDraft={setCreateCampaignDraft}
            />
            <Form.Item
                className="action-buttons-block"
                style={{ marginTop: 20 }}
            >
                <Button onClick={() => handleBack()}>Отменить</Button>
                <Button
                    type="primary"
                    htmlType="submit"
                    loading={isSubmitting}
                >
                    Сохранить
                </Button>
            </Form.Item>
        </Form>
    );
};
export default WildberriesIntegrationForm;
