import { Marketplace, Store, PaymentType } from '@OpenApi/typescript-axios';

export enum PaginationSearchOrderEnum {
    LIMIT = 'limit',
    PAGE = 'page',
}

export enum ProductsFilterEnum {
    MARKETPLACES = 'marketplaces[]',
    STORES = 'stores[]',
}

export type ProductsParamsType = {
    [PaginationSearchOrderEnum.LIMIT]?: number;
    [PaginationSearchOrderEnum.PAGE]?: number;
    [ProductsFilterEnum.MARKETPLACES]?: Array<Marketplace>;
    [ProductsFilterEnum.STORES]?: Array<Store>;
};

export enum CampaignsFilterEnum {
    PAYMENT_TYPES = 'paymentTypes',
}

export type CampaignsParamsType = {
    [CampaignsFilterEnum.PAYMENT_TYPES]?: Array<PaymentType>;
};

export type OptionType = { value: string; label: string };
