import { emptySplitApi as api } from '../../full/emptyApi';

const injectedRtkApi = api.injectEndpoints({
    endpoints: (build) => ({
        getCountryList: build.query<GetCountryListApiResponse, GetCountryListApiArg>({
            query: () => ({ url: '/api/country/' }),
        }),
        getProductCategoriesList: build.query<GetProductCategoriesListApiResponse, GetProductCategoriesListApiArg>({
            query: () => ({ url: '/api/product-category/' }),
        }),
    }),
    overrideExisting: false,
});
export { injectedRtkApi as CommonApi };
export type GetCountryListApiResponse = /** status 200 Get country list */ CountryListDto;
export type GetCountryListApiArg = void;
export type GetProductCategoriesListApiResponse = /** status 200 Get product categories list */ ProductCategoryDtoList;
export type GetProductCategoriesListApiArg = void;
export type CountryDto = {
    id: string;
    title: string;
};
export type CountryListDto = {
    items?: CountryDto[];
};
export type ProductCategoryDto = {
    id?: number | null;
    title: string | null;
};
export type ProductCategoryDtoList = {
    items?: ProductCategoryDto[];
};
export const { useGetCountryListQuery, useGetProductCategoriesListQuery } = injectedRtkApi;
