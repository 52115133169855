import { useEffect, useMemo } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { PlusCircleOutlined } from '@ant-design/icons';

import { useAppSelector, useAppDispatch } from '@Shared/api/hooks';
import { EmptyCampaignsTableView, CampaignsFilter, CampaignsTable } from '@Features/seller/products';
import { CampaignsFilterEnum } from '@Features/seller/products/model/types';
import { Flex, Grid, Typography, Button } from '@Shared/ui';
import {
    CampaignStatusEnum,
    useGetCampaignsListQuery,
} from '@Shared/api/rtkQueryApis/sellerCampaigns/SellerCampaignsApi';
import { useGetTheUserInformationQuery, useAddOnboardingStatusMutation } from '@Shared/api/rtkQueryApis/user/UserApi';
import { SellerOnboardingStatuses } from '@Shared/model/joyrideTypes';
import { setRun, goToStep } from '@Entities/joyride/model/joyrideSlice';
import { isElementInViewport } from '@Shared/utils/isElementInViewPort';

import '../styles.scss';
import { InfoAlert } from './components';

const { Text } = Typography;
const { useBreakpoint } = Grid;

const CampaignsTab = () => {
    const dispatch = useAppDispatch();
    const breakpoints = useBreakpoint();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const { status } = useAppSelector((state) => state.joyride);
    const { data: user, isSuccess: isUserFulfilled } = useGetTheUserInformationQuery();
    const [addOnboardingStatus] = useAddOnboardingStatusMutation();
    const {
        data: campaigns,
        isFetching,
        isSuccess: isCampaignsFulfilled,
    } = useGetCampaignsListQuery({
        paymentTypes: searchParams.getAll(CampaignsFilterEnum.PAYMENT_TYPES).length
            ? searchParams.getAll(CampaignsFilterEnum.PAYMENT_TYPES)
            : undefined,
    });

    // joyride onboarding step
    useEffect(() => {
        const onboardingStatuses = user?.onboarding_statuses || [];
        if (
            status === SellerOnboardingStatuses.CAMPAIGN_LAUNCH &&
            onboardingStatuses.includes(SellerOnboardingStatuses.MARKETPLACE_STORE_INTEGRATION) &&
            !onboardingStatuses.includes(SellerOnboardingStatuses.CAMPAIGN_LAUNCH) &&
            isUserFulfilled &&
            isCampaignsFulfilled
        ) {
            if (campaigns?.items?.length) {
                const stepTarget = document.querySelector('#joyride-launch-campaign');
                if (stepTarget && !isElementInViewport(stepTarget)) {
                    dispatch(setRun(false));
                    stepTarget.scrollIntoView({ behavior: 'smooth', block: 'end' });
                    setTimeout(() => {
                        dispatch(setRun(true));
                        dispatch(goToStep({ status: SellerOnboardingStatuses.CAMPAIGN_LAUNCH, order: 4 }));
                    }, 500);
                } else {
                    dispatch(setRun(true));
                    dispatch(goToStep({ status: SellerOnboardingStatuses.CAMPAIGN_LAUNCH, order: 4 }));
                }
            } else {
                addOnboardingStatus({ status: SellerOnboardingStatuses.CAMPAIGN_LAUNCH });
            }
        }
    }, [
        addOnboardingStatus,
        campaigns?.items?.length,
        dispatch,
        isCampaignsFulfilled,
        isUserFulfilled,
        status,
        user?.onboarding_statuses,
    ]);

    const isShowTopUpBalanceAlert = useMemo(
        () => campaigns?.items?.some((campaign) => campaign.status === CampaignStatusEnum.TopUpBalance),
        [campaigns?.items],
    );

    return (
        <>
            {!user?.seller?.legalInfo && (
                <InfoAlert
                    message="Заполните юридические данные, необходимые для соблюдения закона о маркировке рекламы."
                    action={
                        <Button
                            type="primary"
                            onClick={() => navigate('/legal-info')}
                        >
                            Заполнить
                        </Button>
                    }
                />
            )}
            {isShowTopUpBalanceAlert && (
                <InfoAlert
                    message="Для запуска рекламных кампаний вам необходимо пополнить баланс"
                    action={
                        <Button
                            type="link"
                            onClick={() => navigate('/finances')}
                        >
                            Пополнить
                        </Button>
                    }
                />
            )}
            <Flex
                justify="space-between"
                gap={10}
                vertical={!breakpoints.lg}
                style={{ marginBottom: 24 }}
                className="campaigns-tab"
            >
                <Text type="secondary">В этом разделе представлен общий список ваших рекламных кампаний</Text>
            </Flex>
            <Flex
                justify="space-between"
                gap={10}
                vertical={!breakpoints.lg}
                style={{ marginBottom: 24 }}
            >
                <CampaignsFilter />
                <Button
                    icon={<PlusCircleOutlined />}
                    iconPosition="start"
                    type="primary"
                    onClick={() => navigate('/product-promotion/campaign-creation')}
                >
                    Создать кампанию
                </Button>
            </Flex>

            <CampaignsTable
                data={campaigns?.items || []}
                isLoading={isFetching}
                EmptyTableView={<EmptyCampaignsTableView />}
            />
        </>
    );
};
export default CampaignsTab;
