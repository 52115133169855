import { FC } from 'react';
import { Provider } from 'react-redux';

import TopLevelWrapper from './TopLevelWrapper';

import { store } from '../providers/redux/store';
import Theme from '../providers/AppTheme';

const App: FC = () => {
    return (
        <Theme>
            <Provider store={store}>
                <TopLevelWrapper />
            </Provider>
        </Theme>
    );
};

export default App;
