import dayjs from 'dayjs';
import { theme } from 'antd';

import { Typography } from '@Shared/ui';
import { PromotionDto2 } from '@Shared/api/rtkQueryApis/sellerPromotions';
import { DEFAULT_DATE_FORMAT } from '@Shared/constants';

const { Text } = Typography;

const PeriodCell = ({ promotion }: { promotion: PromotionDto2 }) => {
    const {
        token: { colorTextTertiary, colorText },
    } = theme.useToken();

    const startDate = dayjs(promotion.start_date).format(DEFAULT_DATE_FORMAT);
    const endDate = dayjs(promotion.end_date).format(DEFAULT_DATE_FORMAT);
    const isActive = promotion.active;
    return (
        <Text style={{ color: isActive ? colorText : colorTextTertiary }}>
            {startDate} - {endDate}
        </Text>
    );
};

export default PeriodCell;
