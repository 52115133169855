import { Switch } from '@Shared/ui';
import { useAppDispatch } from '@Shared/api/hooks';
import { showModal } from '@Entities/modals/model/modalsSlice';
import { ModalKeysType } from '@Shared/model/modalTypes';

const VisibleCell = ({
    isVisible,
    isActive,
    promotionId,
}: {
    isVisible: boolean;
    isActive: boolean;
    promotionId: string;
}) => {
    const dispatch = useAppDispatch();

    const handleShowModal = (checked: boolean) => {
        if (checked) {
            dispatch(showModal({ modalKey: ModalKeysType.approvePromotionParticipation, params: { promotionId } }));
        } else {
            dispatch(showModal({ modalKey: ModalKeysType.declinePromotionParticipation, params: { promotionId } }));
        }
    };
    return isActive ? (
        <Switch
            onChange={handleShowModal}
            onClick={(_, e) => e.stopPropagation()}
            checked={isVisible}
        />
    ) : null;
};

export default VisibleCell;
