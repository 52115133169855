import { Layout, theme } from 'antd';
import Icon from '@ant-design/icons';

import { Typography, Row, Col, Flex, Spin } from '@Shared/ui';
import { SellerImg, WebmasterImg, LogoShort } from '@Shared/assets/images/';
import WelcomeRoleCard from '@Features/welcome/WelcomeRoleCard/WelcomeRoleCard';
import { usePostAppApiUserSetRolesMutation } from '@Shared/api/rtkQueryApis/user';

import './styles.scss';

const { Content, Sider } = Layout;
const { Title, Text } = Typography;
const SITE_NAME = 'Takprodam';
const WelcomePage = () => {
    const [postUser, { isLoading }] = usePostAppApiUserSetRolesMutation();
    const {
        token: { colorBgLayout, paddingMD, colorTextLightSolid },
    } = theme.useToken();

    const handleSelectRole = (role: string) => {
        postUser({ userRolesDto: { [role]: true } });
    };

    if (isLoading) {
        return (
            <Spin
                fullscreen
                size="large"
            />
        );
    }

    return (
        <Layout className="app-layout-container">
            <Sider
                trigger={null}
                width={256}
            >
                <Row style={{ padding: paddingMD, gap: '8px' }}>
                    <Icon component={() => <LogoShort />} />
                    <Text style={{ color: colorTextLightSolid, opacity: 0.85 }}>{SITE_NAME}</Text>
                </Row>
                <Col className="welcome-description">
                    <Title
                        level={3}
                        className="welcome-description-title"
                        style={{ color: colorTextLightSolid }}
                    >
                        Добро пожаловать в Takprodam
                    </Title>
                    <Text style={{ color: colorTextLightSolid, opacity: 0.65 }}>Выберите свою роль</Text>
                </Col>
            </Sider>
            <Content style={{ background: colorBgLayout }}>
                <Row className="welcome-header" />
                <Title
                    className="welcome-content-title"
                    level={3}
                >
                    Выберите свою роль
                </Title>
                <Flex
                    justify="center"
                    align="middle"
                    gap={'16px'}
                >
                    <WelcomeRoleCard
                        title="Продавец"
                        description={`Продвигайте ваши товары и привлекайте новых лояльных
                            покупателей, сотрудничая с лучшими вебмастерами`}
                        imageSrc={SellerImg}
                        onSelect={() => handleSelectRole('is_seller')}
                    />
                    <WelcomeRoleCard
                        title="Вебмастер"
                        description={`Повысьте привлекательность и прибыльность ваших
                            интернет-проектов, сотрудничая с лучшими продавцами`}
                        imageSrc={WebmasterImg}
                        onSelect={() => handleSelectRole('is_publisher')}
                    />
                </Flex>
            </Content>
        </Layout>
    );
};

export default WelcomePage;
