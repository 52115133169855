import { emptySplitApi as api } from '../../full/emptyApi';

const injectedRtkApi = api.injectEndpoints({
    endpoints: (build) => ({
        getSellerPromotionsList: build.query<GetSellerPromotionsListApiResponse, GetSellerPromotionsListApiArg>({
            query: (queryArg) => ({
                url: '/api/seller/v1/promotion/',
                params: {
                    page: queryArg.page,
                    limit: queryArg.limit,
                    marketplace_slug: queryArg.marketplaceSlug,
                    store_id: queryArg.storeId,
                    active: queryArg.active,
                },
            }),
        }),
        viewThePromotion: build.query<ViewThePromotionApiResponse, ViewThePromotionApiArg>({
            query: (queryArg) => ({ url: `/api/seller/v1/promotion/${queryArg.id}/` }),
        }),
        patchAppPrivateSellerPromotionUpdate: build.mutation<
            PatchAppPrivateSellerPromotionUpdateApiResponse,
            PatchAppPrivateSellerPromotionUpdateApiArg
        >({
            query: (queryArg) => ({
                url: `/api/seller/v1/promotion/${queryArg.id}/`,
                method: 'PATCH',
                body: queryArg.promotionDto,
            }),
        }),
        patchAppPrivateSellerPromotionSetpromotionvisibility: build.mutation<
            PatchAppPrivateSellerPromotionSetpromotionvisibilityApiResponse,
            PatchAppPrivateSellerPromotionSetpromotionvisibilityApiArg
        >({
            query: (queryArg) => ({
                url: '/api/seller/v1/promotion/set-visibility/',
                method: 'PATCH',
                body: queryArg.setPromotionVisibilityDto,
            }),
        }),
        getProductsListForPromotion: build.query<
            GetProductsListForPromotionApiResponse,
            GetProductsListForPromotionApiArg
        >({
            query: (queryArg) => ({
                url: `/api/seller/v1/promotion/${queryArg.id}/product/`,
                params: { page: queryArg.page, limit: queryArg.limit },
            }),
        }),
        patchAppPrivateSellerPromotionSetpromotionproductvisibility: build.mutation<
            PatchAppPrivateSellerPromotionSetpromotionproductvisibilityApiResponse,
            PatchAppPrivateSellerPromotionSetpromotionproductvisibilityApiArg
        >({
            query: (queryArg) => ({
                url: `/api/seller/v1/promotion/${queryArg.id}/set-visibility/`,
                method: 'PATCH',
                body: queryArg.setPromotionProductVisibilityDto,
            }),
        }),
    }),
    overrideExisting: false,
});
export { injectedRtkApi as SellerPromotionsApi };
export type GetSellerPromotionsListApiResponse = /** status 200 Paginated response */ {
    totalCount?: number;
    page?: number;
    limit?: number;
    items?: PromotionDto2[];
};
export type GetSellerPromotionsListApiArg = {
    page?: number;
    limit?: number;
    marketplaceSlug?: MarketPlaceSlugEnum | null;
    storeId?: string | null;
    active?: boolean | null;
};
export type ViewThePromotionApiResponse = /** status 200 Resource response */ PromotionDto2;
export type ViewThePromotionApiArg = {
    id: string;
};
export type PatchAppPrivateSellerPromotionUpdateApiResponse = unknown;
export type PatchAppPrivateSellerPromotionUpdateApiArg = {
    id: string;
    promotionDto: PromotionDto;
};
export type PatchAppPrivateSellerPromotionSetpromotionvisibilityApiResponse = unknown;
export type PatchAppPrivateSellerPromotionSetpromotionvisibilityApiArg = {
    setPromotionVisibilityDto: SetPromotionVisibilityDto;
};
export type GetProductsListForPromotionApiResponse = /** status 200 Get products list for promotion */ {
    totalCount?: number;
    page?: number;
    limit?: number;
    items?: PromotionProductDto2[];
};
export type GetProductsListForPromotionApiArg = {
    id: string;
    page?: number;
    limit?: number;
};
export type PatchAppPrivateSellerPromotionSetpromotionproductvisibilityApiResponse = unknown;
export type PatchAppPrivateSellerPromotionSetpromotionproductvisibilityApiArg = {
    id: string;
    setPromotionProductVisibilityDto: SetPromotionProductVisibilityDto;
};
export type MarketplaceDto2 = {
    id: string;
    title: string;
    slug: MarketPlaceSlugEnum3;
    description?: string | null;
};
export type StoreDto3 = {
    id: string;
    title: string;
    comment?: string | null;
    logo?: string | null;
    marketplaceStores?: MarketplaceStoreDto2[];
    active: boolean;
};
export type MarketplaceStoreDto2 = {
    id: string;
    marketplace: MarketplaceDto2;
    store: StoreDto3;
};
export type PromotionDto2 = {
    id: string;
    title: string;
    description: string | null;
    marketplace_store: MarketplaceStoreDto2;
    start_date: string;
    end_date: string;
    active?: boolean;
    visible?: boolean;
    orderAmount?: number | null;
    discountValue?: string | null;
};
export type PromotionDto = {
    description: string | null;
};
export type SetPromotionVisibilityDto = {
    select_all?: boolean;
    visible?: boolean;
    promotions?: string[];
};
export type Store2 = {
    id: string;
    title: string;
    logo?: string | null;
};
export type Marketplace = {
    id: string;
    title: string;
    slug: MarketPlaceSlugEnum3;
    description?: string | null;
};
export type MarketplaceStore2 = {
    id: string;
    store?: Store2 | null;
    marketplace?: Marketplace | null;
};
export type Currency = {
    shortCode: string;
    symbol: string;
};
export type ProductCategory = {
    id: number;
    title: string;
};
export type ProductDto = {
    id: string;
    marketplace_store: MarketplaceStore2 | null;
    title: string;
    sku: string;
    description: string;
    price: number | null;
    currency: Currency | null;
    product_category?: ProductCategory | null;
    external_link: string;
    external_id: string;
};
export type PromotionProductDto2 = {
    id: string;
    product: ProductDto;
    price?: number | null;
    price_discount?: number | null;
    price_percent?: number | null;
    visible?: boolean;
};
export type SetPromotionProductVisibilityDto = {
    select_all?: boolean;
    visible?: boolean;
    promotion_products?: string[];
};
export enum MarketPlaceSlugEnum3 {
    Ozon = 'ozon',
    Wildberries = 'wildberries',
}
export enum MarketPlaceSlugEnum {
    Ozon = 'ozon',
    Wildberries = 'wildberries',
}
export const {
    useGetSellerPromotionsListQuery,
    useViewThePromotionQuery,
    usePatchAppPrivateSellerPromotionUpdateMutation,
    usePatchAppPrivateSellerPromotionSetpromotionvisibilityMutation,
    useGetProductsListForPromotionQuery,
    usePatchAppPrivateSellerPromotionSetpromotionproductvisibilityMutation,
} = injectedRtkApi;
