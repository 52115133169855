import { Navigate, createBrowserRouter } from 'react-router-dom';

import { AppLayout } from '@Widgets/publisher/layouts';
import {
    SourcesPage,
    AddSourcePage,
    EditSourcePage,
    ProductsPage,
    ProductCatalogTab,
    LegalInfoPage,
    FinancesPage,
    StatisticsPage,
    PromotionsPage,
    PromotionsTab,
} from '@Pages/publisher';
import { ErrorPage } from '@Pages/errors';

import { public_routes } from './PublicRoutes';

export const publisher_app_router = createBrowserRouter([
    ...public_routes,
    {
        path: '/',
        element: <AppLayout />,
        children: [
            {
                path: '/',
                element: (
                    <Navigate
                        replace
                        to="/sources"
                    />
                ),
            },
            {
                path: '/sources',
                element: <SourcesPage />,
            },
            {
                path: '/sources/add-source',
                element: <AddSourcePage />,
            },
            {
                path: '/sources/edit-source',
                element: <EditSourcePage />,
            },
            {
                path: '/legal-info',
                element: <LegalInfoPage />,
            },
            {
                path: '/products',
                element: <ProductsPage />,
                children: [
                    {
                        path: '',
                        element: <ProductCatalogTab />,
                    },
                ],
            },
            {
                path: '/promotions',
                element: <PromotionsPage />,
                children: [
                    {
                        path: '',
                        element: <PromotionsTab />,
                    },
                ],
            },
            {
                path: '/finances',
                element: <FinancesPage />,
            },
            {
                path: '/statistics',
                element: <StatisticsPage />,
            },
            {
                path: '/404',
                element: <ErrorPage status={404} />,
            },
            {
                path: '*',
                element: (
                    <Navigate
                        replace
                        to="/404"
                    />
                ),
            },
        ],
    },
]);
