import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

export function formatDateUTC(dateString: string | number | Date, format = 'DD.MM.YYYY HH:mm') {
    const utcDate = dayjs(dateString);

    return utcDate.format(format);
}

export function convertToGmtZero(dateStr) {
    // Parse the date string in the local timezone specified
    const localDate = dayjs(dateStr);
    // Convert to UTC
    const utcDate = localDate.utc();
    // Return formatted UTC date
    return utcDate.format('YYYY-MM-DD HH:mm:ss');
}
