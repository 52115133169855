import { MouseEvent } from 'react';
import { theme } from 'antd';
import Icon from '@ant-design/icons';

import { Row, Button } from '@Shared/ui';
import { type PromotionProductDto } from '@Shared/api/rtkQueryApis/publisherPromotions';
import { CopyItemLeftTop } from '@Shared/assets/images/icons';
import { useClipboard } from '@Shared/api/hooks';

const TrackingLinksCell = ({ promotionProduct }: { promotionProduct: PromotionProductDto }) => {
    const { copyTextToClipboard } = useClipboard();
    const {
        token: { borderRadiusLG },
    } = theme.useToken();

    const handleCopyText = (e: MouseEvent<HTMLElement>, link: string, message: string) => {
        e.stopPropagation();
        copyTextToClipboard(link || '', message);
    };

    return (
        <Row style={{ gap: 12 }}>
            <Button
                size="small"
                style={{ borderRadius: borderRadiusLG }}
                iconPosition="end"
                disabled={!promotionProduct?.tracking_link}
                onClick={(e) =>
                    handleCopyText(
                        e,
                        promotionProduct?.tracking_link || '',
                        'Полная партнерская ссылка успешно скопирована',
                    )
                }
                icon={<Icon component={() => <CopyItemLeftTop />} />}
            >
                Полная
            </Button>
            <Button
                size="small"
                iconPosition="end"
                style={{ borderRadius: borderRadiusLG }}
                type="primary"
                disabled={!promotionProduct?.tracking_short_link}
                onClick={(e) =>
                    handleCopyText(
                        e,
                        promotionProduct?.tracking_short_link || '',
                        'Короткая партнерская ссылка успешно скопирована',
                    )
                }
                icon={<Icon component={() => <CopyItemLeftTop />} />}
            >
                Короткая
            </Button>
        </Row>
    );
};

export default TrackingLinksCell;
