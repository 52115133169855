import { CampaignStatusEnum } from '@OpenApi/typescript-axios';

export function getCampaignStatusBadgeType(status: CampaignStatusEnum) {
    let type = 'default';
    switch (status) {
        case CampaignStatusEnum.Active:
            type = 'success';
            break;
        case CampaignStatusEnum.Pending:
            type = 'warning';
            break;
        case CampaignStatusEnum.Disabled:
            type = 'default';
            break;
    }

    return type;
}
