import { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { Space } from '@Shared/ui';
import { useGetSellerPromotionsListQuery } from '@Shared/api/rtkQueryApis/sellerPromotions';
import {
    FilterEnum,
    FilterObjectType,
    defaultFilterObject,
} from '@Features/seller/promotions/promotionsTab/model/types';
import PromotionDetailsDrawer from '@Features/seller/promotions/promotionsTab/promotionsDrawer/PromotionDetailsDrawer';
import { useUpdateSearchParams } from '@Shared/api/hooks/useUpdateSearchParams';

import PromotionsTable from './promotionsTable/PromotionsTable';
import PromotionsFilters from './promotionsFilters/PromotionsFilters';
import { ApprovePromotionParticipationModal, DeclinePromotionParticipationModal } from './modals';

const initialFilterObject = (searchParams: URLSearchParams): FilterObjectType => ({
    page: searchParams.get(FilterEnum.PAGE) || defaultFilterObject.page,
    limit: searchParams.get(FilterEnum.LIMIT) || defaultFilterObject.limit,
    marketplaceSlug: searchParams.get(FilterEnum.MARKETPLACE_SLUG) || '',
    storeId: searchParams.get(FilterEnum.STORE_ID) || '',
});

const PromotionsTableBlock = () => {
    const [searchParams] = useSearchParams();
    const updateSearchParams = useUpdateSearchParams();
    const [currentPromotionId, setPromotionId] = useState('');
    const [isDrawerOpen, setDrawerOpen] = useState(false);
    const [filterObject, setFilterObject] = useState<FilterObjectType>(initialFilterObject(searchParams));
    const { data, isFetching } = useGetSellerPromotionsListQuery({
        page: filterObject.page,
        limit: filterObject.limit,
        marketplaceSlug: filterObject.marketplaceSlug || '',
        storeId: filterObject.storeId || '',
        active: filterObject.active || false,
    });

    useEffect(() => {
        const filteredFilterObject = { ...filterObject };
        Object.keys(filteredFilterObject).forEach((param) => {
            if (filteredFilterObject[param] === defaultFilterObject[param]) {
                delete filteredFilterObject[param];
            }
        });
        updateSearchParams(filteredFilterObject);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterObject]);

    return (
        <>
            <Space
                direction="vertical"
                size={24}
                style={{ width: '100%' }}
            >
                <PromotionsFilters
                    filterObject={filterObject}
                    setFilterObject={setFilterObject}
                />
                <PromotionsTable
                    data={data}
                    isLoading={isFetching}
                    setFilterObject={setFilterObject}
                    setPromotionId={setPromotionId}
                    setDrawerOpen={setDrawerOpen}
                />
            </Space>
            <PromotionDetailsDrawer
                isDrawerOpen={isDrawerOpen}
                setDrawerOpen={setDrawerOpen}
                promotionId={currentPromotionId}
            />
            <ApprovePromotionParticipationModal />
            <DeclinePromotionParticipationModal />
        </>
    );
};
export default PromotionsTableBlock;
