type formatPriceProps = {
    price: number | string;
    currency: { shortCode?: string; symbol?: string };
    locale?: string;
    signDisplay?: 'auto' | 'always' | 'exceptZero' | 'negative' | 'never';
    trailingZeroDisplay?: 'auto' | 'stripIfInteger' | undefined;
};

export const formatPrice = ({
    price = 0,
    currency,
    locale = 'ru-RU',
    signDisplay = 'auto',
    trailingZeroDisplay = 'stripIfInteger',
}: formatPriceProps) => {
    return new Intl.NumberFormat(locale, {
        style: 'currency',
        currency: currency?.shortCode || 'RUB',
        signDisplay,
        trailingZeroDisplay,
    }).format(Number(price));
};
