import { type SelectProps } from 'antd';

import { Grid, Select } from '@Shared/ui';
import { useGetProductCategoriesListQuery } from '@Shared/api/rtkQueryApis/common/commonApi';

const { useBreakpoint } = Grid;

type ProductCategoriesFilterProps<FilterObjectType> = {
    filterObject: FilterObjectType;
    setFilterObject: (arg1: FilterObjectType) => void;
    propName?: keyof FilterObjectType;
    selectProps?: SelectProps;
};

const ProductCategoriesFilter = <FilterObjectType,>({
    filterObject,
    setFilterObject,
    propName = 'categories' as keyof FilterObjectType,
    selectProps = {},
}: ProductCategoriesFilterProps<FilterObjectType>) => {
    const breakpoints = useBreakpoint();
    const { data: categories, isLoading } = useGetProductCategoriesListQuery();

    const categoriesOptions = (categories?.items || []).map((category) => ({
        value: category.id + '',
        label: category.title,
    }));

    return (
        <Select
            style={{ width: breakpoints.md ? 200 : '100%' }}
            loading={isLoading}
            mode="multiple"
            allowClear
            disabled={!categoriesOptions.length}
            filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
            options={categoriesOptions}
            onChange={(option) => setFilterObject({ ...filterObject, [propName]: option })}
            value={categories?.items?.length ? filterObject[propName] : []}
            placeholder="Категории"
            {...selectProps}
        />
    );
};

export default ProductCategoriesFilter;
