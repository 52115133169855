import { useEffect } from 'react';

import { Row, Col, Grid } from '@Shared/ui';
import { useUpdateSearchParams } from '@Shared/api/hooks/useUpdateSearchParams';
import { FilterObjectType } from '@Features/publisher/products/model/types';

import ExportButton from './ExportButton';
// import StoreFilter from './StoreFilter';
import MarketplaceFilter from './MarketplaceFilter';
import FavoriteCheckbox from './FavoriteCheckbox';
import CategoriesFilter from './CategoriesFilter';

const { useBreakpoint } = Grid;

type ProductsFilterProps = {
    filterObject: FilterObjectType;
    setFilterObject: (arg1: FilterObjectType) => void;
    sourceId: string;
};

const ProductCatalogFilters = (props: ProductsFilterProps) => {
    const updateSearchParams = useUpdateSearchParams();
    const breakpoints = useBreakpoint();

    useEffect(() => {
        updateSearchParams(props.filterObject);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.filterObject]);

    return (
        <Row
            justify="space-between"
            style={{ gap: 8, flexDirection: breakpoints.md ? 'row' : 'column' }}
        >
            <Col id="joyride-product-filters">
                <Row
                    wrap
                    align="middle"
                    style={{
                        gap: 12,
                        flexDirection: breakpoints.md ? 'row' : 'column',
                        alignItems: breakpoints.md ? 'center' : 'flex-start',
                    }}
                >
                    {/* <StoreFilter {...props} /> */}
                    <MarketplaceFilter {...props} />
                    <CategoriesFilter {...props} />
                    {breakpoints.md ? <FavoriteCheckbox {...props} /> : null}
                </Row>
            </Col>
            <Col>
                <Row
                    justify="space-between"
                    align="middle"
                >
                    {breakpoints.md ? null : <FavoriteCheckbox {...props} />}
                    <ExportButton sourceId={props.sourceId} />
                </Row>
            </Col>
        </Row>
    );
};

export default ProductCatalogFilters;
