import { Navigate, createBrowserRouter } from 'react-router-dom';

import {
    DashboardPage,
    ProductsPage,
    StoresPage,
    SetupIntegrationPage,
    StoresTab,
    MarketplacesTab,
    ProductsTab,
    CampaignsTab,
    CampaignTypeSelectionPage,
    CampaignPaymentOrderPage,
    MessengerPage,
    NewMessageTab,
    MessagesTab,
    LegalInfoPage,
    FinancesPage,
    FinancesTab,
    ManualVerificationTab,
    StatisticsPage,
    PromotionsPage,
    PromotionsTab,
    EditPromotionPage,
} from '@Pages/seller';
import { ErrorPage } from '@Pages/errors';
import { AppLayout } from '@Widgets/seller/layouts';

import { public_routes } from './PublicRoutes';

export const seller_app_router = createBrowserRouter([
    ...public_routes,
    {
        path: '/',
        element: <AppLayout />,
        children: [
            // {
            //     path: '/',
            //     element: <DashboardPage />,
            // },
            {
                path: '/',
                element: (
                    <Navigate
                        replace
                        to="/stores"
                    />
                ),
            },
            {
                path: '/dashboard',
                element: <DashboardPage />,
            },
            {
                path: '/product-promotion',
                element: <ProductsPage />,
                children: [
                    {
                        path: '',
                        element: <ProductsTab />,
                    },
                    {
                        path: 'campaigns',
                        element: <CampaignsTab />,
                    },
                ],
            },
            {
                path: '/product-promotion/campaign-creation',
                element: <CampaignTypeSelectionPage />,
            },
            {
                path: '/product-promotion/campaigns-payment-order/:slug?',
                element: <CampaignPaymentOrderPage />,
            },
            {
                path: '/stores',
                element: <StoresPage />,
                children: [
                    {
                        path: '',
                        element: <StoresTab />,
                    },
                    {
                        path: 'marketplaces',
                        element: <MarketplacesTab />,
                    },
                ],
            },
            {
                path: '/promotions',
                element: <PromotionsPage />,
                children: [
                    {
                        path: '',
                        element: <PromotionsTab />,
                    },
                ],
            },
            {
                path: '/promotions/:promotionId/edit-promotion',
                element: <EditPromotionPage />,
            },
            {
                path: '/messenger',
                element: <MessengerPage />,
                children: [
                    {
                        path: '',
                        element: <MessagesTab />,
                    },
                    {
                        path: 'new',
                        element: <NewMessageTab />,
                    },
                ],
            },
            {
                path: '/stores/setup-integration',
                element: <SetupIntegrationPage />,
            },
            {
                path: '/legal-info',
                element: <LegalInfoPage />,
            },
            {
                path: '/finances',
                element: <FinancesPage />,
                children: [
                    {
                        path: '',
                        element: <FinancesTab />,
                    },
                    {
                        path: 'manual-verification',
                        element: <ManualVerificationTab />,
                    },
                ],
            },
            {
                path: '/statistics',
                element: <StatisticsPage />,
            },
            {
                path: '/404',
                element: <ErrorPage status={404} />,
            },
            {
                path: '*',
                element: (
                    <Navigate
                        replace
                        to="/404"
                    />
                ),
            },
        ],
    },
]);
