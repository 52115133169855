import { Select, Grid } from '@Shared/ui';
import { FilterObjectType } from '@Features/publisher/products/model/types';
import { useGetProductCategoriesListQuery } from '@Shared/api/rtkQueryApis/common/commonApi';

type ProductsFilterProps = {
    filterObject: FilterObjectType;
    setFilterObject: (arg1: FilterObjectType) => void;
};
const { useBreakpoint } = Grid;

const CategoriesFilter = ({ filterObject, setFilterObject }: ProductsFilterProps) => {
    const breakpoints = useBreakpoint();
    const { data: categories, isLoading } = useGetProductCategoriesListQuery();

    const categoriesOptions = (categories?.items || []).map((category) => ({
        value: category.id + '',
        label: category.title,
    }));

    return (
        <Select
            style={{ width: breakpoints.md ? 200 : '100%' }}
            loading={isLoading}
            mode="multiple"
            allowClear
            disabled={!categoriesOptions.length}
            filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
            options={categoriesOptions}
            onChange={(option) => setFilterObject({ ...filterObject, categories: option })}
            value={categories?.items?.length ? filterObject.categories : []}
            placeholder="Категории"
        />
    );
};

export default CategoriesFilter;
