import dayjs from 'dayjs';

import { Typography } from '@Shared/ui';
import { PromotionDto2 } from '@Shared/api/rtkQueryApis/sellerPromotions';
import { DEFAULT_DATE_FORMAT } from '@Shared/constants';

const { Text } = Typography;

const PeriodCell = ({ promotion }: { promotion: PromotionDto2 | null | undefined }) => {
    const startDate = promotion?.start_date ? dayjs(promotion?.start_date).format(DEFAULT_DATE_FORMAT) : '';
    const endDate = promotion?.end_date ? dayjs(promotion?.end_date).format(DEFAULT_DATE_FORMAT) : '';
    return (
        <Text>
            {startDate} - {endDate}
        </Text>
    );
};

export default PeriodCell;
