import { emptySplitApi as api } from '../../full/emptyApi';

const injectedRtkApi = api.injectEndpoints({
    endpoints: (build) => ({
        getSellerCommissionsList: build.query<GetSellerCommissionsListApiResponse, GetSellerCommissionsListApiArg>({
            query: (queryArg) => ({
                url: '/api/seller/v1/commission/',
                params: {
                    page: queryArg.page,
                    limit: queryArg.limit,
                    createdAtFrom: queryArg.createdAtFrom,
                    createdAtTo: queryArg.createdAtTo,
                    sku: queryArg.sku,
                    marketplaceStoreId: queryArg.marketplaceStoreId,
                },
            }),
        }),
        patchAppPrivateSellerCommissionDecline: build.mutation<
            PatchAppPrivateSellerCommissionDeclineApiResponse,
            PatchAppPrivateSellerCommissionDeclineApiArg
        >({
            query: (queryArg) => ({
                url: `/api/seller/v1/commission/${queryArg.id}/decline/`,
                method: 'PATCH',
                body: queryArg.declineReasonDto,
            }),
        }),
        patchAppPrivateSellerCommissionApprove: build.mutation<
            PatchAppPrivateSellerCommissionApproveApiResponse,
            PatchAppPrivateSellerCommissionApproveApiArg
        >({
            query: (queryArg) => ({
                url: `/api/seller/v1/commission/${queryArg.id}/approve/`,
                method: 'PATCH',
                body: queryArg.changeTotalCartReasonDto,
            }),
        }),
    }),
    overrideExisting: false,
});
export { injectedRtkApi as SellerCommissionsApi };
export type GetSellerCommissionsListApiResponse = /** status 200 Paginated response */ {
    totalCount?: number;
    page?: number;
    limit?: number;
    items?: CommissionDto[];
};
export type GetSellerCommissionsListApiArg = {
    page?: number;
    limit?: number;
    createdAtFrom?: string | null;
    createdAtTo?: string | null;
    sku?: string | null;
    marketplaceStoreId?: string | null;
};
export type PatchAppPrivateSellerCommissionDeclineApiResponse = unknown;
export type PatchAppPrivateSellerCommissionDeclineApiArg = {
    id: string;
    declineReasonDto: DeclineReasonDto;
};
export type PatchAppPrivateSellerCommissionApproveApiResponse = unknown;
export type PatchAppPrivateSellerCommissionApproveApiArg = {
    id: string;
    changeTotalCartReasonDto: ChangeTotalCartReasonDto;
};
export type Store2 = {
    id: string;
    title: string;
    logo?: string | null;
};
export type Marketplace = {
    id: string;
    title: string;
    slug: MarketPlaceSlugEnum3;
    description?: string | null;
};
export type MarketplaceStore2 = {
    id: string;
    store?: Store2 | null;
    marketplace?: Marketplace | null;
};
export type Currency = {
    shortCode: string;
    symbol: string;
};
export type ProductCategory = {
    id: number;
    title: string;
};
export type Product = {
    id: number;
    marketplace_store?: MarketplaceStore2 | null;
    title: string;
    sku: string;
    description: string;
    price: string;
    currency?: Currency | null;
    external_link: string;
    external_id: string;
    product_category?: ProductCategory | null;
};
export type MpTrafficReportDto = {
    date: string;
};
export type CommissionDto = {
    id: string;
    status: CommissionStatusEnum;
    decline_reason?: CommissionDeclineReasonEnum2 | null;
    change_total_cart_reason?: CommissionChangeTotalCartReasonEnum2 | null;
    marketplace_store?: MarketplaceStore2 | null;
    order_product?: Product | null;
    total_cart?: number | null;
    total_fee?: number | null;
    fee_value?: number | null;
    mp_traffic_report?: MpTrafficReportDto | null;
};
export type DeclineReasonDto = {
    decline_reason: CommissionDeclineReasonEnum;
};
export type ChangeTotalCartReasonDto = {
    change_total_cart_reason: CommissionChangeTotalCartReasonEnum;
    new_total_cart: number;
};
export enum CommissionStatusEnum {
    Pending = 'pending',
    Approved = 'approved',
    Declined = 'declined',
}
export enum CommissionDeclineReasonEnum2 {
    Refusal = 'refusal',
    NotRedeemed = 'not_redeemed',
    Refund = 'refund',
}
export enum CommissionChangeTotalCartReasonEnum2 {
    PartiallyPaid = 'partially_paid',
}
export enum MarketPlaceSlugEnum3 {
    Ozon = 'ozon',
    Wildberries = 'wildberries',
}
export enum CommissionDeclineReasonEnum {
    Refusal = 'refusal',
    NotRedeemed = 'not_redeemed',
    Refund = 'refund',
}
export enum CommissionChangeTotalCartReasonEnum {
    PartiallyPaid = 'partially_paid',
}
export const {
    useGetSellerCommissionsListQuery,
    usePatchAppPrivateSellerCommissionDeclineMutation,
    usePatchAppPrivateSellerCommissionApproveMutation,
} = injectedRtkApi;
