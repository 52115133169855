import { Space, Card, Row, Col, Typography, Grid } from '@Shared/ui';
import { Breadcrumbs, PageContent, type BreadcrumbItemType } from '@Features/shared';
import { LegalInfoForm } from '@Features/publisher/legalInfo';
// import './styles.scss';

const { Text, Title, Link } = Typography;
const { useBreakpoint } = Grid;

const pageBreadcrumbs: BreadcrumbItemType[] = [
    {
        path: '/legal-info',
        title: 'Юридические данные',
    },
];

const LegalInfoPage = () => {
    const breakpoints = useBreakpoint();

    return (
        <PageContent>
            <Breadcrumbs items={pageBreadcrumbs} />
            <Row
                gutter={[10, 10]}
                style={{
                    rowGap: breakpoints.md ? 10 : 0,
                    flexDirection: breakpoints.lg ? 'row' : 'column',
                    flexWrap: breakpoints.lg ? 'nowrap' : 'wrap',
                }}
            >
                <Col flex="auto">
                    <LegalInfoForm />
                </Col>
                <Col style={{ flex: breakpoints.lg ? '320px 0 0' : 'auto' }}>
                    <Card style={{ width: '100%', background: '#fff' }}>
                        <Space
                            direction="vertical"
                            size="middle"
                        >
                            <Title
                                type="secondary"
                                level={3}
                            >
                                FAQ
                            </Title>
                            <Text type="secondary">Зачем нужно размечать рекламу?</Text>
                            <Text type="secondary">Могу ли я изменить юр. данные?</Text>
                            <Link
                                onClick={() => {
                                    window.location.href = `mailto:${process.env.SUPPORT_EMAIL}`;
                                }}
                            >
                                Нужна помощь?
                            </Link>
                        </Space>
                    </Card>
                </Col>
            </Row>
        </PageContent>
    );
};
export default LegalInfoPage;
