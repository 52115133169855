import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { theme } from 'antd';

import {
    selectState,
    SetupIntegrationFormType,
    submitForm,
} from '@Entities/forms/seller/stores/model/setupIntegration';
import { FormIntegrationsEnum } from '@Shared/model/seller/types';
import { Button, Form, Input, Typography, Divider, Popover, Grid } from '@Shared/ui';
import { OzonIntegrationDto } from '@OpenApi/typescript-axios';
import { ConfirmLeavePrompt } from '@Features/shared';
import { showNotification, NotificationType } from '@Entities/notifications/model/notificationSlice';
import { useGetIntegrationDataFromExtension } from '@Shared/api/hooks';

import CreateCampaignDraftBlock from '../CreateCampaignDraftBlock';

import './styles.scss';

type SetupIntegrationFormProps = {
    storeId: string;
};

const { Title, Link } = Typography;
const { useBreakpoint } = Grid;

const fieldsHelperText = {
    organization_id: (
        <>
            {'Находится в разделе Аналитика > Внешний трафик ('}
            <Link
                href="https://seller.ozon.ru/app/advertisement/vendor-traffic"
                target="_blank"
            >
                https://seller.ozon.ru/app/advertisement/vendor-traffic
            </Link>
            {') в личном кабинете продавца Озон. Скопируйте цифры, идущие после префикса'}
            <strong> vendor_org_</strong>
        </>
    ),
    seller_client_id: (
        <>
            {
                // eslint-disable-next-line max-len
                'Находится в разделе API Ключи в личном кабинете продавца Ozon. Подробная инструкция находится в разделе "Как подключить Seller API?" ('
            }
            <Link
                href="https://seller-edu.ozon.ru/api-ozon/how-to-api"
                target="_blank"
            >
                https://seller-edu.ozon.ru/api-ozon/how-to-api
            </Link>
            {')'}
        </>
    ),
    seller_api_key: (
        <>
            {
                // eslint-disable-next-line max-len
                'Можно сгенерировать в разделе API Ключи в личном кабинете продавца Ozon. Подробная инструкция находится в разделе "Как подключить Seller API?" ('
            }
            <Link
                href="https://seller-edu.ozon.ru/api-ozon/how-to-api"
                target="_blank"
            >
                https://seller-edu.ozon.ru/api-ozon/how-to-api
            </Link>
            {')'}
        </>
    ),
    performance_client_id: (
        <>
            {
                // eslint-disable-next-line max-len
                'Можно добавить в разделе API Ключи (вкладка Performance API) в личном кабинете продавца Ozon. Подробная инструкция находится в разделе "Начало работы с Performance API?" ('
            }
            <Link
                href="https://seller-edu.ozon.ru/api-ozon/external-api"
                target="_blank"
            >
                https://seller-edu.ozon.ru/api-ozon/external-api
            </Link>
            {')'}
        </>
    ),
    performance_api_key: (
        <>
            {
                // eslint-disable-next-line max-len
                'Можно добавить в разделе API Ключи (вкладка Performance API) в личном кабинете продавца Ozon. Подробная инструкция находится в разделе "Начало работы с Performance API?" ('
            }
            <Link
                href="https://seller-edu.ozon.ru/api-ozon/external-api"
                target="_blank"
            >
                https://seller-edu.ozon.ru/api-ozon/external-api
            </Link>
            {')'}
        </>
    ),
};

const OzonIntegrationForm = ({ storeId }: SetupIntegrationFormProps) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const breakpoints = useBreakpoint();
    const [form] = Form.useForm();
    useGetIntegrationDataFromExtension(form);
    const [hasUnsavedChanges, setUnsavedChanges] = useState(false);
    const [createCampaignDraft, setCreateCampaignDraft] = useState(true);
    const { isSubmitted, isSubmitting, formData, errors }: SetupIntegrationFormType<OzonIntegrationDto> =
        useSelector(selectState);

    const {
        token: { colorTextDescription },
    } = theme.useToken();

    const handleSubmit = (formValues: typeof formData) => {
        dispatch(
            submitForm({
                key: FormIntegrationsEnum.sellerOzonSetupIntegrationForm,
                withNotification: true,
                notificationOptions: {
                    successMessage: 'Интеграция была успешно создана',
                    errorMessage: 'Ошибка при попытке создания интеграции',
                },
                formData: formValues,
                params: { storeId, createCampaignDraft },
            }),
        );
    };

    const onFinish = (formData) => {
        handleSubmit(formData);
    };

    const onFinishFailed = (errorInfo) => {
        console.error('Failed:', errorInfo);
    };

    const handleBack = useCallback(() => {
        navigate(-1);
    }, [navigate]);

    useEffect(() => {
        if (isSubmitting) {
            setUnsavedChanges(false);
        }
    }, [isSubmitting]);

    useEffect(() => {
        if (isSubmitted) {
            if (createCampaignDraft) {
                setTimeout(() =>
                    dispatch(
                        showNotification({
                            componentProps: {
                                type: NotificationType.Info,
                                message: 'У вас появилась новая рекламная кампания',
                                description:
                                    // eslint-disable-next-line max-len
                                    'Проверьте и отредактируйте информацию о ней. Запустите кампанию, чтобы начать быстрое продвижение товаров.',
                                btn: (
                                    <Button
                                        type="link"
                                        onClick={() => navigate('/product-promotion/campaigns')}
                                    >
                                        Рекламные кампании
                                    </Button>
                                ),
                            },
                        }),
                        1000,
                    ),
                );
            }
        }
    }, [createCampaignDraft, dispatch, isSubmitted, navigate]);

    const renderTooltip = (field) => (
        <Popover
            content={<div style={{ maxWidth: 500 }}>{fieldsHelperText[field]}</div>}
            placement={breakpoints.md ? 'right' : 'top'}
        >
            <QuestionCircleOutlined style={{ marginLeft: 5, color: colorTextDescription }} />
        </Popover>
    );

    return (
        <Form
            form={form}
            name="integration-ozon"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            onFieldsChange={() => setUnsavedChanges(true)}
            autoComplete="off"
            className="setup-integration-page__form"
        >
            <ConfirmLeavePrompt
                hasUnsavedChanges={hasUnsavedChanges}
                allowsPaths={['/stores/setup-integration']}
            />
            <Form.Item
                hasFeedback
                label={<>ID организации {renderTooltip('organization_id')}</>}
                name="organization_id"
                validateTrigger="onBlur"
                rules={[{ required: true, message: 'Это поле обязательно к заполнению' }]}
                style={{ marginTop: 20 }}
                validateStatus={errors?.organization_id ? 'error' : ''}
                help={errors?.organization_id || null}
            >
                <Input placeholder="Укажите ID организации" />
            </Form.Item>
            <Divider />
            <Title
                style={{ marginBottom: 8 }}
                level={5}
            >
                Seller API
            </Title>
            <Form.Item
                hasFeedback
                label={<>Client ID {renderTooltip('seller_client_id')}</>}
                name="seller_client_id"
                validateTrigger="onBlur"
                rules={[{ required: true, message: 'Это поле обязательно к заполнению' }]}
                style={{ marginTop: 20 }}
                validateStatus={errors?.seller_client_id ? 'error' : ''}
                help={errors?.seller_client_id || null}
            >
                <Input placeholder="Укажите Client ID" />
            </Form.Item>
            <Form.Item
                hasFeedback
                label={<>API key {renderTooltip('seller_api_key')}</>}
                name="seller_api_key"
                validateTrigger="onBlur"
                rules={[{ required: true, message: 'Это поле обязательно к заполнению' }]}
                style={{ marginTop: 20 }}
                validateStatus={errors?.seller_api_key ? 'error' : ''}
                help={errors?.seller_api_key || null}
            >
                <Input placeholder="Укажите API key" />
            </Form.Item>
            <Divider />
            <Title
                style={{ marginBottom: 8 }}
                level={5}
            >
                Perfomance API
            </Title>
            <Form.Item
                hasFeedback
                label={<>Client ID {renderTooltip('performance_client_id')}</>}
                name="performance_client_id"
                validateTrigger="onBlur"
                rules={[{ required: true, message: 'Это поле обязательно к заполнению' }]}
                style={{ marginTop: 20 }}
                validateStatus={errors?.performance_client_id ? 'error' : ''}
                help={errors?.performance_client_id || null}
            >
                <Input placeholder="Укажите Client ID" />
            </Form.Item>
            <Form.Item
                hasFeedback
                label={<>Client Secret {renderTooltip('performance_api_key')}</>}
                name="performance_api_key"
                validateTrigger="onBlur"
                rules={[{ required: true, message: 'Это поле обязательно к заполнению' }]}
                style={{ marginTop: 20 }}
                validateStatus={errors?.performance_api_key ? 'error' : ''}
                help={errors?.performance_api_key || null}
            >
                <Input placeholder="Укажите Client Secret" />
            </Form.Item>
            <CreateCampaignDraftBlock
                createCampaignDraft={createCampaignDraft}
                setCreateCampaignDraft={setCreateCampaignDraft}
            />
            <Form.Item
                className="action-buttons-block"
                style={{ marginTop: 20 }}
            >
                <Button onClick={() => handleBack()}>Отменить</Button>
                <Button
                    type="primary"
                    htmlType="submit"
                    loading={isSubmitting}
                >
                    Сохранить
                </Button>
            </Form.Item>
        </Form>
    );
};
export default OzonIntegrationForm;
