import { RollbackOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';

import { CampaignTypeSelection } from '@Features/seller';
import { Breadcrumbs, PageContent, type BreadcrumbItemType } from '@Features/shared';
import { Button, Card, Grid, Row, Typography } from '@Shared/ui';

const { Title } = Typography;
const { useBreakpoint } = Grid;

const pageBreadcrumbs: BreadcrumbItemType[] = [
    {
        path: '/product-promotion',
        title: 'Продвижение товаров',
    },
    {
        path: 'campaigns',
        title: 'Рекламные кампании',
    },
    {
        path: '/product-promotion/campaign-creation',
        title: 'Создание кампании',
    },
];

const CampaignTypeSelectionPage = () => {
    const navigate = useNavigate();
    const breakpoints = useBreakpoint();

    return (
        <PageContent>
            <Breadcrumbs items={pageBreadcrumbs} />

            <Card className="campaign-type-selection__card">
                <Row
                    align="top"
                    style={{ columnGap: 16, rowGap: 24, flexDirection: breakpoints.lg ? 'row' : 'column-reverse' }}
                    wrap={false}
                >
                    <Row>
                        <Title
                            level={3}
                            style={{ marginTop: 4 }}
                        >
                            Создание кампании
                        </Title>
                        <Button
                            icon={<RollbackOutlined />}
                            iconPosition="end"
                            type="link"
                            onClick={() => navigate('/product-promotion/campaigns')}
                        >
                            {breakpoints.md ? 'Назад' : ''}
                        </Button>
                    </Row>
                </Row>
                <CampaignTypeSelection />
            </Card>
        </PageContent>
    );
};
export default CampaignTypeSelectionPage;
