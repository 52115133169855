import dayjs from 'dayjs';
import weekday from 'dayjs/plugin/weekday';
import localeData from 'dayjs/plugin/localeData';
import { RangePickerProps } from 'antd/es/date-picker';

import { DatePicker, Grid } from '@Shared/ui';

dayjs.extend(weekday);
dayjs.extend(localeData);

const { useBreakpoint } = Grid;

type DateRangeFilterProps<FilterObjectType> = {
    filterObject: FilterObjectType;
    setFilterObject: (arg1: FilterObjectType) => void;
    fromPropName?: keyof FilterObjectType;
    toPropName?: keyof FilterObjectType;
    format?: string;
    rangePickerProps?: RangePickerProps;
};

const { RangePicker } = DatePicker;

const DateRangeFilter = <FilterObjectType,>({
    filterObject,
    setFilterObject,
    fromPropName = 'createdAtFrom' as keyof FilterObjectType,
    toPropName = 'createdAtTo' as keyof FilterObjectType,
    format = 'DD.MM.YYYY',
    rangePickerProps,
}: DateRangeFilterProps<FilterObjectType>) => {
    const breakpoints = useBreakpoint();
    const normalizedValue = [
        filterObject[fromPropName] ? dayjs(filterObject[fromPropName], format) : null,
        filterObject[toPropName] ? dayjs(filterObject[toPropName], format) : null,
    ];

    const onRangeChange = (dates) => {
        if (dates && dates.length === 2) {
            const startDate = dayjs(dates[0]).format('YYYY-MM-DD HH:mm:ss');
            const endDate = dayjs(dates[1]).hour(23).minute(59).format('YYYY-MM-DD HH:mm:ss');

            setFilterObject({
                ...filterObject,
                [fromPropName]: startDate,
                [toPropName]: endDate,
            });
        } else {
            setFilterObject({
                ...filterObject,
                [fromPropName]: '',
                [toPropName]: '',
            });
        }
    };

    const disabledDate = (current) => {
        return current && current > dayjs().endOf('day');
    };

    return (
        <RangePicker
            value={normalizedValue}
            onChange={onRangeChange}
            placeholder={['Начало периода', 'Конец периода']}
            disabledDate={disabledDate}
            format={format}
            style={{ width: breakpoints.md ? 280 : '100%' }}
            {...rangePickerProps}
        />
    );
};

export default DateRangeFilter;
