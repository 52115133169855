import { useState, useEffect } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import { InputNumber, Row } from '@Shared/ui';
import { type FeeSetting2, type ProductCampaign } from '@Shared/api/rtkQueryApis/sellerCampaigns';
import { formatPrice } from '@Shared/utils/formatPrice';

import { ActionType } from './types';

type ComissionCellProps = {
    product: ProductCampaign;
    campaign_fee_setting: FeeSetting2 | null | undefined;
    applyAction: (params: Record<string, unknown>) => void;
};

const getCommissionValue = (fee_setting, campaign_fee_setting) =>
    Math.floor((fee_setting?.fee_value || campaign_fee_setting?.fee_value) * 100) || 10;

export const ComissionCell = ({
    product: { id, fee_setting, product },
    campaign_fee_setting,
    applyAction,
}: ComissionCellProps) => {
    const [value, setValue] = useState(getCommissionValue(fee_setting, campaign_fee_setting));

    const applyActionDebounced = useDebouncedCallback((value) => {
        applyAction({
            producstsIds: [id],
            action: ActionType.changeCommission,
            commission: value / 100,
        });
    }, 1000);

    useEffect(() => {
        setValue(getCommissionValue(fee_setting, campaign_fee_setting));
    }, [fee_setting, campaign_fee_setting]);

    const handleChange = (value: string | number | null) => {
        setValue(value);
        applyActionDebounced(value);
    };

    return (
        <Row
            align="middle"
            style={{ gap: 8 }}
            wrap={false}
        >
            <InputNumber
                changeOnBlur
                value={value}
                min={2}
                max={100}
                onChange={handleChange}
                addonAfter="%"
                step="1"
                style={{ width: 100 }}
            />
            <span style={{ wordBreak: 'break-word', whiteSpace: 'pre-wrap' }}>
                {`( ${formatPrice({ price: (+product.price / 100) * value, currency: product.currency || {} })} )`}
            </span>
        </Row>
    );
};
