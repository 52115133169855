import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { OptionType, PaginationSearchOrderEnum, ProductsFilterEnum } from '@Features/seller/products/model/types';
import { Flex, Grid, Select } from '@Shared/ui';
import { MarketplaceDto, StoreDto3 } from '@OpenApi/typescript-axios';

const { useBreakpoint } = Grid;

import './styles.scss';

type ProductsFilterProps = {
    marketplaces: MarketplaceDto[];
    stores: StoreDto3[];
};

export const ProductsFilter: FC<ProductsFilterProps> = ({ marketplaces = [], stores = [] }) => {
    const breakpoints = useBreakpoint();
    const [searchParams, setSearchParams] = useSearchParams();
    const setSearchParamsRef = useRef(setSearchParams);
    setSearchParamsRef.current = setSearchParams;

    const [selectedMarketplace, setSelectedMarketplace] = useState<OptionType[] | null>(null);
    const [selectedStore, setSelectedStore] = useState<OptionType[] | null>(null);

    const marketplaceOptions = useMemo(
        () =>
            marketplaces.map((marketplace) => ({
                value: marketplace.slug,
                label: marketplace.title,
            })) || [],
        [marketplaces],
    );

    const storeOptions = useMemo(
        () => stores.map((store) => ({ value: store.id, label: store.title }) as OptionType) || [],
        [stores],
    );

    const filterChange = useCallback((paramName: string, value: string | null) => {
        setSearchParamsRef.current((prevParams) => handleFilterParamsChange(prevParams, paramName, value));
    }, []);

    const handleFilterParamsChange = (searchParams: URLSearchParams, paramName: string, value: string | [] | null) => {
        const newSearchParams = new URLSearchParams(searchParams);
        if (value?.length) {
            if (Array.isArray(value)) {
                newSearchParams.set(paramName, value[0]);
                value.slice(1).forEach((newValue) => {
                    if (newSearchParams.has(paramName)) {
                        newSearchParams.append(paramName, newValue);
                    } else {
                        newSearchParams.set(paramName, newValue);
                    }
                });
            } else {
                newSearchParams.set(paramName, value);
            }
        } else {
            newSearchParams.delete(paramName);
        }
        newSearchParams.delete(PaginationSearchOrderEnum.PAGE);
        return newSearchParams;
    };

    useEffect(() => {
        const marketplacesParam = searchParams.getAll(ProductsFilterEnum.MARKETPLACES);
        if (marketplacesParam) {
            const param = marketplaceOptions.filter((item) => marketplacesParam.includes(item.value));
            setSelectedMarketplace(param || null);
        }
        const storesParam = searchParams.getAll(ProductsFilterEnum.STORES);
        if (storesParam) {
            const param = storeOptions.filter((item) => storesParam.includes(item.value));
            setSelectedStore(param || null);
        }
    }, [marketplaceOptions, searchParams, setSelectedMarketplace, setSelectedStore, storeOptions]);

    return (
        <Flex
            className="products-filter"
            gap="middle"
            vertical={!breakpoints.lg}
        >
            <div className={breakpoints.lg ? 'products-filter__item' : ''}>
                <Select
                    className="products-filter__select"
                    mode="multiple"
                    allowClear
                    disabled={!storeOptions.length}
                    options={storeOptions}
                    onChange={(option) => filterChange(ProductsFilterEnum.STORES, option)}
                    value={selectedStore}
                    placeholder="Магазин"
                />
            </div>
            <div className={breakpoints.lg ? 'products-filter__item' : ''}>
                <Select
                    className="products-filter__select"
                    mode="multiple"
                    allowClear
                    options={marketplaceOptions}
                    onChange={(option) => filterChange(ProductsFilterEnum.MARKETPLACES, option)}
                    value={selectedMarketplace}
                    placeholder="Маркетплейс"
                />
            </div>
        </Flex>
    );
};
