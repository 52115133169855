import { useDispatch } from 'react-redux';

import { Button } from '@Shared/ui';
import { DownloadSaveUpload } from '@Shared/assets/images/icons';
import { showNotification, NotificationType } from '@Entities/notifications/model/notificationSlice';

const BASE_URL = process.env.SERVER_URL;

const DownloadStatisticsButton = () => {
    const dispatch = useDispatch();
    const handleDownload = async () => {
        const response = await fetch(`${BASE_URL}/api/seller/v1/statistics/`, {
            credentials: 'include',
        });
        if (response.ok) {
            const blob = await response.blob();
            const downloadUrl = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = downloadUrl;
            link.download = 'statistics_export.csv';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(downloadUrl);
        } else {
            dispatch(
                showNotification({
                    componentProps: {
                        type: NotificationType.Error,
                        message: 'Произошла ошибка при попытке скачивания статистики',
                    },
                }),
            );
        }
    };

    return (
        <Button
            onClick={handleDownload}
            icon={<DownloadSaveUpload />}
            iconPosition="start"
            type="primary"
        >
            Скачать
        </Button>
    );
};

export default DownloadStatisticsButton;
