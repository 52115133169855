import { FC, useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Pagination } from 'antd';

import { PaginationSearchOrderEnum } from '@Features/seller/products/model/types';

const DEFAULT_LIMIT = 20;
const DEFAULT_PAGE = 1;

type ProductsPaginationProps = {
    total: number;
};

export const ProductsPagination: FC<ProductsPaginationProps> = ({ total }) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const setSearchParamsRef = useRef(setSearchParams);
    setSearchParamsRef.current = setSearchParams;

    const [currentPage, setCurrentPage] = useState<number>(DEFAULT_PAGE);

    const [currentLimit, setCurrentLimit] = useState<number>(DEFAULT_LIMIT);

    const onPaginationChange = (page: number, pageSize: number) => {
        setSearchParamsRef.current((prevParams) => {
            const newSearchParams = new URLSearchParams(prevParams);
            newSearchParams.set(PaginationSearchOrderEnum.LIMIT, `${pageSize}`);
            newSearchParams.set(PaginationSearchOrderEnum.PAGE, `${page}`);
            return newSearchParams;
        });
    };

    useEffect(() => {
        const newLimit = searchParams.has(PaginationSearchOrderEnum.LIMIT)
            ? Number(searchParams.get(PaginationSearchOrderEnum.LIMIT))
            : DEFAULT_LIMIT;
        const newPage = searchParams.has(PaginationSearchOrderEnum.PAGE)
            ? Number(searchParams.get(PaginationSearchOrderEnum.PAGE))
            : DEFAULT_PAGE;
        setCurrentLimit(newLimit);
        setCurrentPage(newPage);
    }, [searchParams]);

    return (
        <Pagination
            pageSize={currentLimit}
            showSizeChanger
            hideOnSinglePage
            defaultCurrent={currentPage}
            total={total}
            onChange={onPaginationChange}
        />
    );
};
