import { useState, useEffect } from 'react';
import { useNavigate, Outlet } from 'react-router-dom';

import { PageContent, Breadcrumbs, type BreadcrumbItemType } from '@Features/shared';
import { Card, Space } from '@Shared/ui';

const pageBreadcrumbs = [
    {
        path: '/promotions',
        title: 'Акции и промокоды',
    },
];

const tabList = [
    {
        key: '/promotions',
        tab: 'Товары со скидками',
    },
    // {
    //     key: '/product-promotion/campaigns',
    //     tab: 'Рекламные кампании',
    // },
];

const PromotionsPage = () => {
    const navigate = useNavigate();
    const [breadcrumbs, setBreadcrumbs] = useState<BreadcrumbItemType[]>(pageBreadcrumbs);
    const [activeTabKey, setActiveTabKey] = useState<string>(location?.pathname || tabList[0].key);

    const setTabAndBreadcrumbs = (key: string) => {
        setActiveTabKey(key);
        setBreadcrumbs(() => {
            const tabBreadcrumb = tabList.find((tab) => tab.key === key);
            const isDefaultTab = pageBreadcrumbs.find((el) => el.path === key);
            if (isDefaultTab) {
                return pageBreadcrumbs;
            }
            return [
                ...pageBreadcrumbs,
                {
                    path: tabBreadcrumb!.key,
                    title: tabBreadcrumb!.tab,
                },
            ];
        });
    };

    useEffect(() => {
        setTabAndBreadcrumbs(location.pathname);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname]);

    const onTabChange = (key: string) => {
        setTabAndBreadcrumbs(key);
        navigate(key);
    };

    console.log(3333, breadcrumbs);

    return (
        <PageContent>
            <Breadcrumbs items={breadcrumbs} />

            <Space
                direction="vertical"
                style={{ width: '100%' }}
            >
                <Card
                    onTabChange={onTabChange}
                    activeTabKey={activeTabKey}
                    tabList={tabList}
                    style={{ width: '100%', background: '#fff' }}
                >
                    <Outlet />
                </Card>
            </Space>
        </PageContent>
    );
};
export default PromotionsPage;
