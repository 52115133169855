import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { OptionType, CampaignsFilterEnum } from '@Features/seller/products/model/types';
import { Flex, Grid, Select } from '@Shared/ui';
import { SellerPaymentTypesApi } from '@OpenApi/typescript-axios';

const { useBreakpoint } = Grid;
// const { Search } = Input;

import './styles.scss';

const sellerPaymentTypesApi = new SellerPaymentTypesApi();

export const CampaignsFilter: FC = () => {
    const breakpoints = useBreakpoint();
    const [searchParams, setSearchParams] = useSearchParams();
    const setSearchParamsRef = useRef(setSearchParams);
    setSearchParamsRef.current = setSearchParams;

    const [selectedTypes, setSelectedTypes] = useState<OptionType[] | null>(null);
    const [paymentTypes, setPaymentTypes] = useState([]);
    const [isTypesLoading, setTypesLoading] = useState(false);

    useEffect(() => {
        if (!paymentTypes.length) {
            const fetchTypes = async () => {
                setTypesLoading(true);

                try {
                    const types = await sellerPaymentTypesApi.getPaymentTypesList();
                    setPaymentTypes(types.data.items);
                    setTypesLoading(false);
                } catch (err) {
                    console.error(err);
                    setTypesLoading(false);
                }
            };

            fetchTypes();
        }
    }, [paymentTypes]);

    const paymentTypeOptions = useMemo(
        () =>
            paymentTypes.map((paymentType) => ({ value: paymentType.id, label: paymentType.title }) as OptionType) ||
            [],
        [paymentTypes],
    );

    const filterChange = useCallback((paramName: string, value: string | null) => {
        setSearchParamsRef.current((prevParams) => handleFilterParamsChange(prevParams, paramName, value));
    }, []);

    const handleFilterParamsChange = (searchParams: URLSearchParams, paramName: string, value: string | [] | null) => {
        const newSearchParams = new URLSearchParams(searchParams);
        if (value?.length) {
            if (Array.isArray(value)) {
                newSearchParams.set(paramName, value[0]);
                value.slice(1).forEach((newValue) => {
                    if (newSearchParams.has(paramName)) {
                        newSearchParams.append(paramName, newValue);
                    } else {
                        newSearchParams.set(paramName, newValue);
                    }
                });
            } else {
                newSearchParams.set(paramName, value);
            }
        } else {
            newSearchParams.delete(paramName);
        }
        return newSearchParams;
    };

    useEffect(() => {
        const paymentTypeParam = searchParams.getAll(CampaignsFilterEnum.PAYMENT_TYPES);
        if (paymentTypeParam) {
            const param = paymentTypeOptions.filter((item) => paymentTypeParam.includes(item.value + ''));
            setSelectedTypes(param || null);
        }
    }, [searchParams, setSelectedTypes, paymentTypeOptions]);

    return (
        <Flex
            className="campaigns-filter"
            gap="middle"
            vertical={!breakpoints.lg}
        >
            <div className={breakpoints.lg ? 'campaigns-filter__item' : ''}>
                <Select
                    className="campaigns-filter__select"
                    mode="multiple"
                    allowClear
                    disabled={!paymentTypeOptions.length}
                    options={paymentTypeOptions}
                    onChange={(option) => filterChange(CampaignsFilterEnum.PAYMENT_TYPES, option)}
                    value={selectedTypes}
                    placeholder="Тип кампании"
                    loading={isTypesLoading}
                />
            </div>
            {/* <div className={breakpoints.lg ? 'products-filter__item' : ''}>
                <Search
                    placeholder="Поиск"
                    allowClear
                    // onSearch={onSearch}
                    // style={{ width: 304 }}
                />
            </div> */}
        </Flex>
    );
};
