import { Select, Grid } from '@Shared/ui';
import { MarketPlaceSlugEnum } from '@Shared/api/full';
import { FilterObjectType } from '@Features/publisher/products/model/types';

type ProductsFilterProps = {
    filterObject: FilterObjectType;
    setFilterObject: (arg1: FilterObjectType) => void;
};

const { useBreakpoint } = Grid;

const MarketplaceFilter = ({ filterObject, setFilterObject }: ProductsFilterProps) => {
    const breakpoints = useBreakpoint();
    const marketplacesOptions = Object.keys(MarketPlaceSlugEnum).map((marketplace) => ({
        label: marketplace,
        value: MarketPlaceSlugEnum[marketplace],
    }));
    return (
        <Select
            style={{ width: breakpoints.md ? 200 : '100%' }}
            mode="multiple"
            allowClear
            disabled={!marketplacesOptions.length}
            options={marketplacesOptions}
            filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
            onChange={(option) => setFilterObject({ ...filterObject, marketplaces: option })}
            value={filterObject.marketplaces}
            placeholder="Маркетплейсы"
        />
    );
};

export default MarketplaceFilter;
