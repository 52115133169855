import { Dispatch, SetStateAction } from 'react';
import Icon from '@ant-design/icons';

import { renderEmpty } from '@Features/shared';
import { Table, Typography, Tooltip } from '@Shared/ui';
import { FilterObjectType } from '@Features/seller/promotions/promotionsTab/model/types';
import { GetSellerPromotionsListApiResponse, PromotionDto2 } from '@Shared/api/rtkQueryApis/sellerPromotions';
import { QuestionCircle } from '@Shared/assets/images/icons';

import { MPTitleCell, StatusCell, PeriodCell, VisibleCell, EditButtonCell } from './cells';

type PromotionsTableProps = {
    data: GetSellerPromotionsListApiResponse | undefined;
    isLoading: boolean;
    setFilterObject: Dispatch<SetStateAction<FilterObjectType>>;
    setPromotionId: Dispatch<SetStateAction<string>>;
    setDrawerOpen: Dispatch<SetStateAction<boolean>>;
};

const { Text } = Typography;

const columns = [
    {
        title: 'Название акции',
        dataIndex: 'title',
        key: 'title',
        width: 300,
    },
    {
        title: 'Магазин',
        dataIndex: 'marketplace_store',
        key: 'marketplace_store',
        render: (marketplace_store: Pick<PromotionDto2, 'marketplace_store'>, promotions: PromotionDto2) => (
            <MPTitleCell
                marketplaceStore={marketplace_store}
                isActive={promotions.active}
            />
        ),
    },
    {
        title: 'Статус',
        dataIndex: 'active',
        key: 'active',
        render: (active: Pick<PromotionDto2, 'active'>) => <StatusCell isActive={!!active} />,
    },
    {
        title: 'Период',
        dataIndex: 'start_date',
        key: 'start_date',
        render: (_, promotion: PromotionDto2) => <PeriodCell promotion={promotion} />,
    },
    {
        title: (
            <>
                Участие &nbsp;
                <Tooltip title="Остановите свое участие в акции">
                    <Icon component={() => <QuestionCircle style={{ opacity: '0.45' }} />} />
                </Tooltip>
            </>
        ),
        align: 'right',
        dataIndex: 'visible',
        key: 'visible',
        render: (_, promotion: PromotionDto2) => (
            <VisibleCell
                isVisible={!!promotion.visible}
                isActive={!!promotion.active}
                promotionId={promotion.id}
            />
        ),
    },
    {
        key: 'editButton',
        width: 80,
        render: (_, promotion: PromotionDto2) => (
            <EditButtonCell
                isActive={!!promotion.active}
                promotionId={promotion.id}
            />
        ),
    },
];

const PromotionsTable = ({ data, isLoading, setFilterObject, setPromotionId, setDrawerOpen }: PromotionsTableProps) => {
    return (
        <Table
            scroll={{ x: '1000px' }}
            dataSource={data?.items}
            columns={columns}
            rowKey="id"
            // showHeader={!!data?.items?.length}
            onRow={(record) => {
                return {
                    onClick: () => {
                        setPromotionId(record.id);
                        setDrawerOpen(true);
                    },
                };
            }}
            loading={isLoading}
            pagination={{
                showSizeChanger: true,
                hideOnSinglePage: true,
                pageSizeOptions: ['10', '25', '50'],
                pageSize: data?.limit,
                position: ['bottomLeft'],
                current: data?.page,
                total: data?.totalCount,
                onChange: (page, pageSize) =>
                    setFilterObject((prevFilterObject) => {
                        const hasPageSizeChanged = pageSize !== +prevFilterObject.limit;
                        return {
                            ...prevFilterObject,
                            page: hasPageSizeChanged ? 1 : page,
                            limit: pageSize,
                        };
                    }),
            }}
            locale={{
                emptyText: renderEmpty({
                    content: <Text>Нет доступных скидок</Text>,
                }),
            }}
        />
    );
};

export default PromotionsTable;
