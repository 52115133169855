import { FileOutlined } from '@ant-design/icons';
import { theme } from 'antd';

import { Avatar, Typography } from '@Shared/ui';
import { type ProductDto } from '@Shared/api/rtkQueryApis/sellerPromotions';

const { Text } = Typography;

const ProductNameCell = ({ product }: { product: ProductDto | null | undefined }) => {
    const {
        token: { marginSM },
    } = theme.useToken();

    return (
        <>
            <Avatar
                shape="square"
                icon={<FileOutlined />}
                style={{ float: 'left', marginRight: marginSM }}
            />

            <Text strong>{product?.title}</Text>
            <br />
            <Text type="secondary">{product?.product_category?.title}</Text>
        </>
    );
};

export default ProductNameCell;
