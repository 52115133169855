import React from 'react';
import Icon from '@ant-design/icons';
import { message } from 'antd';

import { renderEmpty } from '@Features/shared';
import { Table, Typography, Button, Row } from '@Shared/ui';
import { type ProductCampaignDto, GetProductsListWithLinksBySourceApiResponse } from '@Shared/api/full';
import { PriceCell, ProductNameCell } from '@Features/shared/TableCells/products';
import { CopyItemLeftTop } from '@Shared/assets/images/icons';

import FavoriteCell from './FavoriteCell';

import './styles.scss';

const { Text } = Typography;

type ProductCatalogTableProps = {
    data: GetProductsListWithLinksBySourceApiResponse | undefined;
    isProductsLoading: boolean;
    setCurrentPage: (arg1: number) => void;
    setCurrentProductId: (arg1: string) => void;
    setDrawerOpen: (arg1: boolean) => void;
};

const ProductCatalogTable = ({
    data,
    isProductsLoading,
    setCurrentPage,
    setCurrentProductId,
    setDrawerOpen,
}: ProductCatalogTableProps) => {
    const [messageApi, contextHolder] = message.useMessage();

    const handleCopyLink = (e: React.MouseEventHandler<HTMLElement>, tracking_link: string) => {
        e.stopPropagation();
        navigator.clipboard.writeText(tracking_link);
        messageApi.open({
            type: 'success',
            content: 'Партнерская ссылка успешно скопирована',
        });
    };

    const columns = [
        {
            width: 48,
            render: (_, product: ProductCampaignDto) => (
                <FavoriteCell
                    isFavorite={product?.favorite || false}
                    productId={product.id}
                />
            ),
        },
        {
            title: 'Название товара',
            dataIndex: 'title',
            key: 'title',
            width: 220,
            ellipsis: true,
            render: (text: unknown, { product }: ProductCampaignDto) => <ProductNameCell product={product} />,
        },
        {
            title: 'Стоимость',
            dataIndex: 'price',
            key: 'price',
            render: (text: unknown, { product }: ProductCampaignDto) => <PriceCell product={product} />,
        },
        {
            title: 'Комиссия',
            dataIndex: 'price',
            key: 'price',
            render: (text: unknown, productCampaign: ProductCampaignDto) => (
                <>{(productCampaign?.fee_setting?.fee_value || 0) * 100}%</>
            ),
        },
        {
            title: 'Категория',
            dataIndex: 'category',
            key: 'category',
            render: (_, { product }: ProductCampaignDto) => <>{product?.product_category?.title || ''}</>,
        },
        {
            title: 'Магазин',
            key: 'store',
            render: (text: unknown, { product }: ProductCampaignDto) => product?.marketplace_store?.store?.title,
        },
        {
            title: 'Маркетплейс',
            key: 'marketplace',
            render: (text: unknown, { product }: ProductCampaignDto) => product?.marketplace_store?.marketplace?.title,
        },
        {
            title: 'Партнерская ссылка',
            dataIndex: 'tracking_link',
            key: 'tracking_link',
            ellipsis: true,
            render: (text: unknown, { tracking_link }: ProductCampaignDto) => (
                <Row
                    wrap={false}
                    align="middle"
                    id="joyride-products-external-link"
                    style={{
                        filter: tracking_link ? 'unset' : 'blur(3px)',
                        pointerEvents: tracking_link ? 'auto' : 'none',
                    }}
                >
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={tracking_link || ''}
                        className="product-campaign-table__product-tracking-link"
                    >
                        {tracking_link || 'https://fake.link/1A2B3C'}
                    </a>
                    <Button
                        type="link"
                        onClick={(e) => handleCopyLink(e, tracking_link)}
                        icon={<Icon component={() => <CopyItemLeftTop />} />}
                    />
                </Row>
            ),
        },
    ];

    return (
        <>
            {contextHolder}
            <Table
                dataSource={data?.items?.map((item) => ({ ...item, key: item.id }))}
                loading={isProductsLoading}
                className="product-campaign-table"
                columns={columns}
                scroll={{ x: '1000px' }}
                pagination={{
                    hideOnSinglePage: true,
                    pageSize: data?.limit,
                    position: ['bottomLeft'],
                    current: data?.page,
                    total: data?.totalCount,
                    onChange: setCurrentPage,
                }}
                rowClassName={(record) => (record.favorite ? 'product-campaign-table__favorite' : '')}
                onRow={(record) => {
                    return {
                        onClick: () => {
                            setCurrentProductId(record.id);
                            setDrawerOpen(true);
                        },
                    };
                }}
                locale={{
                    emptyText: renderEmpty({
                        content: <Text>У вас пока нет доступных продуктов для продвижения</Text>,
                    }),
                }}
            />
        </>
    );
};

export default ProductCatalogTable;
