import { useEffect } from 'react';

import { selectModals } from '@Entities/keyValue/selectors';
import { useAppDispatch, useAppSelector } from '@Shared/api/hooks';
import { Form, Row, Button, Grid, Select, Alert, InputNumber } from '@Shared/ui';
import { showMessage, MessageType } from '@Entities/messages/model/messagesSlice';
import {
    usePatchAppPrivateSellerCommissionApproveMutation,
    CommissionChangeTotalCartReasonEnum2,
    ChangeTotalCartReasonDto,
} from '@Shared/api/rtkQueryApis/sellerCommissions';
import { normalizeErrorsObject } from '@Shared/utils/normalizeErrorsObject';
import { changeCommissionReasonTextMap } from '@Features/seller/finances/manualVerification/model/types';

const { useBreakpoint } = Grid;

const ApproveCommissionForm = ({ handleModalClose }: { handleModalClose: () => void }) => {
    const breakpoints = useBreakpoint();
    const [form] = Form.useForm();
    const [approveCommission, { isLoading, error }] = usePatchAppPrivateSellerCommissionApproveMutation();
    const dispatch = useAppDispatch();

    const modalState = useAppSelector(selectModals);

    const handleApproveCommission = async (formValues: ChangeTotalCartReasonDto) => {
        const commissionId = modalState.params.commissionId;
        if (commissionId) {
            const res = await approveCommission({ id: commissionId, changeTotalCartReasonDto: formValues });
            if (res.data) {
                dispatch(
                    showMessage({
                        componentProps: {
                            type: MessageType.Success,
                            content: 'Сумма комиссии успешно изменена',
                        },
                    }),
                );
                handleModalClose();
            }
        }
    };

    const reasonOptions = Object.values(CommissionChangeTotalCartReasonEnum2 || {}).map((reasonKey) => ({
        value: reasonKey,
        label: changeCommissionReasonTextMap[reasonKey],
    }));

    useEffect(() => {
        const errors = normalizeErrorsObject(error?.data?.errors);
        const formErrors = Object.keys(errors).map((field) => ({ name: field, errors: [errors[field]] }));
        form.setFields(formErrors);
    }, [error, form]);

    return (
        <Form
            form={form}
            name="approve-commission"
            onFinish={handleApproveCommission}
            autoComplete="off"
        >
            <Form.Item
                labelCol={{ span: 24 }}
                label="Причина подтверждения суммы заказа"
                name="change_total_cart_reason"
                validateTrigger="onBlur"
                rules={[{ required: true, message: 'Это поле обязательно к заполнению' }]}
                style={{ marginTop: 20 }}
            >
                <Select
                    style={{ width: '100%' }}
                    options={reasonOptions}
                    placeholder="Причина отклонения"
                />
            </Form.Item>
            <Form.Item
                labelCol={{ span: 24 }}
                label="Итоговая сумма заказа"
                name="new_total_cart"
                validateTrigger="onBlur"
                rules={[{ required: true, message: 'Это поле обязательно к заполнению' }]}
                style={{ marginTop: 20 }}
            >
                <InputNumber
                    style={{ width: '100%' }}
                    placeholder="Введите сумму комиссии"
                    addonAfter="₽"
                />
            </Form.Item>
            <Alert
                message="За ложные отклонения взимается штраф 1000 ₽"
                type="warning"
                banner
                style={{ padding: 20, marginBottom: 24 }}
            />
            <Row
                style={{
                    justifyContent: 'space-between',
                    flexDirection: breakpoints.sm ? 'row' : 'column-reverse',
                    gap: 10,
                }}
            >
                <Button
                    onClick={handleModalClose}
                    style={{ flex: breakpoints.sm ? 'unset' : 1 }}
                >
                    Отменить
                </Button>
                <Button
                    type="primary"
                    htmlType="submit"
                    style={{ flex: breakpoints.sm ? 'unset' : 1 }}
                    loading={isLoading}
                >
                    Подтвердить
                </Button>
            </Row>
        </Form>
    );
};
export default ApproveCommissionForm;
