import { FC, MouseEvent, ReactNode, useEffect } from 'react';
import { useHref, useLinkClickHandler } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import { ArrowRightOutlined, UserOutlined } from '@ant-design/icons';

import { ProductDto } from '@OpenApi/typescript-axios';
import { Button, Flex, Col, Typography, Avatar, Divider } from '@Shared/ui';

import './styles.scss';

const { Text, Title } = Typography;

type ProductDetailsProps = {
    product: ProductDto;
    setDrawerExtraContent: (arg1: ReactNode) => void;
    setDrawerTitle: (arg1: ReactNode) => void;
};

export const ProductDetails: FC<ProductDetailsProps> = ({ product, setDrawerExtraContent, setDrawerTitle }) => {
    const linkToStoresPage = '/stores';
    const href = useHref(linkToStoresPage);
    const handleClick = useLinkClickHandler(linkToStoresPage);
    const { title, logo } = product.marketplace_store?.store || {};

    const productDetails = [
        {
            term: 'Цена:',
            desc: `${product.price} ${product.currency?.symbol}`,
            descParams: { strong: true },
        },
        { term: 'Артикул товара:', desc: product.sku, descParams: { copyable: true } },
    ];

    useEffect(() => {
        setDrawerExtraContent(<></>);
    }, [setDrawerExtraContent]);

    useEffect(() => {
        setDrawerTitle(<>Детали</>);
    }, [setDrawerTitle]);

    return (
        <>
            <Col className="product-details__wrap">
                <Title
                    level={4}
                    className="product-details__title"
                >
                    {product.title}
                </Title>
                <Text type="secondary">{product.description}</Text>
                <Title level={5}>Индификаторы</Title>
                <ConfigProvider
                    theme={{
                        token: {
                            colorWarning: '#FF5F00',
                        },
                    }}
                >
                    <dl className="product-details__details">
                        {productDetails.map((detail) => (
                            <>
                                <dt>
                                    <Text
                                        type="secondary"
                                        strong
                                    >
                                        {detail.term}
                                    </Text>
                                </dt>
                                <dd className="product-details__description">
                                    <Text
                                        type="warning"
                                        {...detail.descParams}
                                    >
                                        {detail.desc}
                                    </Text>
                                </dd>
                            </>
                        ))}
                    </dl>
                </ConfigProvider>
                <Title level={5}>Магазин</Title>
                <Divider className="product-details__divider" />
                <Flex
                    align="center"
                    gap={12}
                >
                    <Avatar
                        key={title}
                        shape="square"
                        size={36}
                        icon={
                            logo ? (
                                <img
                                    src={logo || ''}
                                    alt={title}
                                />
                            ) : (
                                <UserOutlined />
                            )
                        }
                    />
                    <Text className="marketplace-title">{title}</Text>
                </Flex>
                <Divider className="product-details__divider" />
            </Col>
            <Flex
                vertical
                className="store-details__delete-button"
            >
                <Button
                    icon={<ArrowRightOutlined />}
                    iconPosition="end"
                    type="primary"
                    href={href}
                    onClick={(event) => {
                        if (!event.defaultPrevented) {
                            handleClick(event as MouseEvent<HTMLAnchorElement, MouseEvent>);
                        }
                    }}
                >
                    Перейти в маркетплейс
                </Button>
            </Flex>
        </>
    );
};
