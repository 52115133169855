import { useEffect } from 'react';
import Joyride, { EVENTS, CallBackProps } from 'react-joyride';

import { useAppSelector, useAppDispatch } from '@Shared/api/hooks';
import { setRun, nextStep, goToStep, setSteps } from '@Entities/joyride/model/joyrideSlice';
import { useGetTheUserInformationQuery, useAddOnboardingStatusMutation } from '@Shared/api/rtkQueryApis/user';
import { PublisherOnboardingStatuses } from '@Shared/model/joyrideTypes';

import { publisherJoyrideSteps } from './publisherSteps';

const PublisherJoyRide = () => {
    const dispatch = useAppDispatch();
    const { run, stepIndex, steps } = useAppSelector((state) => state.joyride);
    const { data: user, isSuccess: isUserFulfilled } = useGetTheUserInformationQuery();
    const [addOnboardingStatus] = useAddOnboardingStatusMutation();

    useEffect(() => {
        dispatch(setSteps(publisherJoyrideSteps));
    }, [dispatch]);

    useEffect(() => {
        if (isUserFulfilled) {
            const onboardingStatuses = user?.onboarding_statuses || [];
            const hasLegalInfo = user.publisher?.legalInfo;

            if (!onboardingStatuses.includes(PublisherOnboardingStatuses.LEGAL_INFO) && hasLegalInfo) {
                addOnboardingStatus({ status: PublisherOnboardingStatuses.LEGAL_INFO });
            } else if (
                onboardingStatuses.includes(PublisherOnboardingStatuses.SOURCE_VERIFICATION) &&
                !onboardingStatuses.includes(PublisherOnboardingStatuses.LEGAL_INFO)
            ) {
                dispatch(setRun(true));
                dispatch(goToStep({ status: PublisherOnboardingStatuses.LEGAL_INFO }));
            } else if (
                !onboardingStatuses.includes(PublisherOnboardingStatuses.GETTING_STARTED) &&
                onboardingStatuses.includes(PublisherOnboardingStatuses.LEGAL_INFO) &&
                onboardingStatuses.includes(PublisherOnboardingStatuses.SOURCE_VERIFICATION)
            ) {
                dispatch(setRun(true));
                dispatch(goToStep({ status: PublisherOnboardingStatuses.GETTING_STARTED, order: 1 }));
            }
        }
    }, [addOnboardingStatus, dispatch, isUserFulfilled, user]);

    const handleJoyrideCallback = async (data: CallBackProps) => {
        const { action, type, step } = data;

        if (type === EVENTS.STEP_AFTER && action === 'next') {
            if (step?.pauseAfterNext) {
                dispatch(setRun(false));
            } else {
                dispatch(nextStep());
            }
            if (step?.stepStatus && step?.postStatusToServer) {
                addOnboardingStatus({ status: step.stepStatus });
            }
        }

        if (type === EVENTS.TOUR_END) {
            dispatch(setRun(false));
        }
    };

    return (
        <Joyride
            steps={steps}
            run={run}
            stepIndex={stepIndex}
            continuous={true}
            showSkipButton={false}
            callback={handleJoyrideCallback}
            hideBackButton={true}
            disableOverlayClose={true}
            disableScrollParentFix
            disableCloseOnEsc
            locale={{
                next: 'Далее',
                skip: 'Пропустить',
                last: 'Финиш',
            }}
            styles={{
                options: {
                    zIndex: 1000, // Ensure it appears above other elements
                    primaryColor: '#FF6A2A',
                },
                tooltip: {
                    borderRadius: 10,
                    fontFamily: 'Rubik',
                },
                buttonNext: {
                    borderRadius: 10,
                    paddingInline: 16,
                },
                buttonBack: {
                    borderRadius: 10,
                    paddingInline: 16,
                },
                buttonClose: {
                    display: 'none', // Hide the close (X) button
                },
            }}
        />
    );
};

export default PublisherJoyRide;
