import { type SelectProps } from 'antd';

import { Grid, Select } from '@Shared/ui';
import { useGetStoresListQuery } from '@Shared/api/rtkQueryApis/sellerStores/SellerStoresApi';

const { useBreakpoint } = Grid;

type SellerStoreFilterProps<FilterObjectType> = {
    filterObject: FilterObjectType;
    setFilterObject: (arg1: FilterObjectType) => void;
    propName?: keyof FilterObjectType;
    selectProps?: SelectProps;
};

const SellerStoreFilter = <FilterObjectType,>({
    filterObject,
    setFilterObject,
    propName = 'storeId' as keyof FilterObjectType,
    selectProps = {},
}: SellerStoreFilterProps<FilterObjectType>) => {
    const breakpoints = useBreakpoint();
    const { data: stores, isLoading } = useGetStoresListQuery();

    const storesOptions = (stores?.items || []).map((store) => ({
        value: store.id,
        label: store.title,
    }));
    const isValueExistInOptions = storesOptions.find((option) => option.value === filterObject[propName]);

    return (
        <Select
            placeholder="Выберите магазин"
            onChange={(option) => setFilterObject({ ...filterObject, [propName]: option })}
            value={isValueExistInOptions ? filterObject[propName] : ''}
            style={{ width: breakpoints.md ? 240 : '100%' }}
            options={[{ value: '', label: 'Все магазины' }, ...storesOptions]}
            loading={isLoading}
            {...selectProps}
        />
    );
};

export default SellerStoreFilter;
