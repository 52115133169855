import { emptySplitApi as api } from '../../full/emptyApi';

const injectedRtkApi = api.injectEndpoints({
    endpoints: (build) => ({
        patchAppPrivateSellerCampaignActivate: build.mutation<
            PatchAppPrivateSellerCampaignActivateApiResponse,
            PatchAppPrivateSellerCampaignActivateApiArg
        >({
            query: (queryArg) => ({ url: `/api/seller/v1/campaign/${queryArg.slug}/activate/`, method: 'PATCH' }),
        }),
        patchAppPrivateSellerCampaignDisable: build.mutation<
            PatchAppPrivateSellerCampaignDisableApiResponse,
            PatchAppPrivateSellerCampaignDisableApiArg
        >({
            query: (queryArg) => ({ url: `/api/seller/v1/campaign/${queryArg.slug}/disable/`, method: 'PATCH' }),
        }),
        viewTheCampaign: build.query<ViewTheCampaignApiResponse, ViewTheCampaignApiArg>({
            query: (queryArg) => ({ url: `/api/seller/v1/campaign/${queryArg.slug}/` }),
        }),
        patchAppPrivateSellerCampaignUpdate: build.mutation<
            PatchAppPrivateSellerCampaignUpdateApiResponse,
            PatchAppPrivateSellerCampaignUpdateApiArg
        >({
            query: (queryArg) => ({
                url: `/api/seller/v1/campaign/${queryArg.slug}/`,
                method: 'PATCH',
                body: queryArg.campaignDto,
            }),
        }),
        getCampaignsList: build.query<GetCampaignsListApiResponse, GetCampaignsListApiArg>({
            query: (queryArg) => ({
                url: '/api/seller/v1/campaign/',
                params: { payment_types: queryArg.paymentTypes },
            }),
        }),
        getProductsListForCampaign: build.query<
            GetProductsListForCampaignApiResponse,
            GetProductsListForCampaignApiArg
        >({
            query: (queryArg) => ({
                url: `/api/seller/v1/campaign/${queryArg.slug}/product/`,
                params: { page: queryArg.page, limit: queryArg.limit },
            }),
        }),
        patchAppPrivateSellerCampaignChangecommission: build.mutation<
            PatchAppPrivateSellerCampaignChangecommissionApiResponse,
            PatchAppPrivateSellerCampaignChangecommissionApiArg
        >({
            query: (queryArg) => ({
                url: `/api/seller/v1/campaign/${queryArg.slug}/product/change-commission/`,
                method: 'PATCH',
                body: queryArg.changeCommissionDto,
            }),
        }),
        patchAppPrivateSellerCampaignSetvisibility: build.mutation<
            PatchAppPrivateSellerCampaignSetvisibilityApiResponse,
            PatchAppPrivateSellerCampaignSetvisibilityApiArg
        >({
            query: (queryArg) => ({
                url: `/api/seller/v1/campaign/${queryArg.slug}/product/set-visibility/`,
                method: 'PATCH',
                body: queryArg.setVisibilityDto,
            }),
        }),
    }),
    overrideExisting: false,
});
export { injectedRtkApi as SellerCampaignsApi };
export type PatchAppPrivateSellerCampaignActivateApiResponse = unknown;
export type PatchAppPrivateSellerCampaignActivateApiArg = {
    slug: string;
};
export type PatchAppPrivateSellerCampaignDisableApiResponse = unknown;
export type PatchAppPrivateSellerCampaignDisableApiArg = {
    slug: string;
};
export type ViewTheCampaignApiResponse = /** status 200 Resource response */ CampaignDto2;
export type ViewTheCampaignApiArg = {
    slug: string;
};
export type PatchAppPrivateSellerCampaignUpdateApiResponse = unknown;
export type PatchAppPrivateSellerCampaignUpdateApiArg = {
    slug: string;
    campaignDto: CampaignDto;
};
export type GetCampaignsListApiResponse = /** status 200 Get campaigns list */ CampaignDtoList;
export type GetCampaignsListApiArg = {
    paymentTypes?: {
        ''?: number[];
    };
};
export type GetProductsListForCampaignApiResponse = /** status 200 Get products list for campaign */ {
    totalCount?: number;
    page?: number;
    limit?: number;
    items?: ProductCampaign[];
};
export type GetProductsListForCampaignApiArg = {
    slug: string;
    page?: number;
    limit?: number;
};
export type PatchAppPrivateSellerCampaignChangecommissionApiResponse = unknown;
export type PatchAppPrivateSellerCampaignChangecommissionApiArg = {
    slug: string;
    changeCommissionDto: ChangeCommissionDto;
};
export type PatchAppPrivateSellerCampaignSetvisibilityApiResponse = unknown;
export type PatchAppPrivateSellerCampaignSetvisibilityApiArg = {
    slug: string;
    setVisibilityDto: SetVisibilityDto;
};
export type Store2 = {
    id: string;
    title: string;
    logo?: string | null;
};
export type Marketplace = {
    id: string;
    title: string;
    slug: MarketPlaceSlugEnum3;
    description?: string | null;
};
export type MarketplaceStore2 = {
    id: string;
    store?: Store2 | null;
    marketplace?: Marketplace | null;
};
export type PaymentType = {
    id: number;
    title: string;
    type: PaymentTypeEnum;
};
export type FeeSetting2 = {
    id: string;
    fee_type?: FeeTypeEnum;
    fee_value?: string;
    system_rate?: string;
    min_total_fee?: string;
};
export type SourceCampaignFee = {
    id: string;
};
export type CampaignDto2 = {
    slug?: string | null;
    marketplace_store?: MarketplaceStore2 | null;
    title: string | null;
    description?: string | null;
    logo?: string | null;
    marketplace_url?: string | null;
    payment_type?: PaymentType | null;
    status?: CampaignStatusEnum | null;
    hold_time: number | null;
    fee_setting: FeeSetting2 | null;
    source_campaign_fees?: SourceCampaignFee[];
};
export type FeeSetting = {
    fee_value?: string;
};
export type CampaignDto = {
    title: string | null;
    hold_time: number | null;
    fee_setting: FeeSetting | null;
};
export type CampaignDtoList = {
    items?: CampaignDto2[];
};
export type Campaign = {
    id: number;
    title: string;
    slug: string;
    description: string;
    logo?: string | null;
    marketplace_url: string;
    payment_type?: PaymentType | null;
    status?: CampaignStatusEnum;
    holdTime?: number;
    fee_setting?: FeeSetting2 | null;
};
export type Currency = {
    shortCode: string;
    symbol: string;
};
export type ProductCategory = {
    id: number;
    title: string;
};
export type Product = {
    id: number;
    marketplace_store?: MarketplaceStore2 | null;
    title: string;
    sku: string;
    description: string;
    price: string;
    currency?: Currency | null;
    external_link: string;
    external_id: string;
    product_category?: ProductCategory | null;
};
export type ProductCampaign = {
    id: string;
    campaign: Campaign;
    product: Product;
    fee_setting?: FeeSetting2 | null;
    visible?: boolean;
    favorite?: boolean;
};
export type ChangeCommissionDto = {
    select_all?: boolean;
    commission: number | null;
    products?: string[];
};
export type SetVisibilityDto = {
    select_all?: boolean;
    visible?: boolean;
    products?: string[];
};
export enum MarketPlaceSlugEnum3 {
    Ozon = 'ozon',
    Wildberries = 'wildberries',
}
export enum PaymentTypeEnum {
    Cpa = 'cpa',
    Cpp = 'cpp',
    Barter = 'barter',
}
export enum CampaignStatusEnum {
    Pending = 'pending',
    Active = 'active',
    TopUpBalance = 'top_up_balance',
    AddLegalInfo = 'add_legal_info',
    Verifying = 'verifying',
    Disabled = 'disabled',
}
export enum FeeTypeEnum {
    Fixed = 'fixed',
    Percentage = 'percentage',
}
export const {
    usePatchAppPrivateSellerCampaignActivateMutation,
    usePatchAppPrivateSellerCampaignDisableMutation,
    useViewTheCampaignQuery,
    usePatchAppPrivateSellerCampaignUpdateMutation,
    useGetCampaignsListQuery,
    useGetProductsListForCampaignQuery,
    usePatchAppPrivateSellerCampaignChangecommissionMutation,
    usePatchAppPrivateSellerCampaignSetvisibilityMutation,
} = injectedRtkApi;
