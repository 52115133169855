import { Row, Grid } from '@Shared/ui';
import { FilterObjectType } from '@Features/seller/promotions/promotionsTab/model/types';
import { SellerStoreFilter, MarketplaceFilter, CheckboxFilter } from '@Features/shared/filters';

const { useBreakpoint } = Grid;

type PromotionsFilterProps = {
    filterObject: FilterObjectType;
    setFilterObject: (arg1: FilterObjectType) => void;
};

const PromotionsFilters = (props: PromotionsFilterProps) => {
    const breakpoints = useBreakpoint();

    return (
        <Row
            wrap
            align="middle"
            style={{
                gap: 12,
                flexDirection: breakpoints.md ? 'row' : 'column',
                alignItems: breakpoints.md ? 'center' : 'flex-start',
            }}
        >
            <SellerStoreFilter<FilterObjectType> {...props} />
            <MarketplaceFilter<FilterObjectType> {...props} />
            <CheckboxFilter<FilterObjectType>
                {...props}
                label="Только доступные"
            />
        </Row>
    );
};

export default PromotionsFilters;
